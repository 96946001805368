import React, { useCallback, useState } from "react";
import './viewersAddForm.scss';
import { getCsrfToken } from "../utils";
import { useNavigate } from "react-router-dom";
import xmarkSvg from '../../assets/xmarkBlue.svg';
import { TCookbook, TCookbookPerm, cookbookPermOptions } from "../../types/cookbook";

type TViewersAddForm = {
    cookbook: TCookbook;
    isVisible: boolean;
    setIsVisible: (v: boolean) => void;
    refreshCookbook: (cookbookId: string) => void;
}

export const ViewersAddForm = ({
    cookbook,
    isVisible,
    setIsVisible,
    refreshCookbook
}: TViewersAddForm) => {
    const [loginName, setLoginName] = useState("");
    const [perm, setPerm] = useState<TCookbookPerm>("READ");

    const [shareLink, setShareLink] =  useState("");

    const onGenerateLink = useCallback(() => {
        const csrfToken = getCsrfToken();

        fetch('/api/share_cookbook',
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                cookbookId: cookbook.id,
                expiresIn: 15,
            })
        }).then(response => response.json())
        .then(data => {
            if (!("token" in data)) {
                console.log("Error encountered when generating share link")
                return;       
            }

            const token = data.token as string;
            const protocol = window.location.protocol.startsWith("https") ? "https://" : "http://"
            const newShareLink = `${protocol}${window.location.host}/shared/${token}?cookbookId=${cookbook.id}`;
            setShareLink(newShareLink);

            navigator.clipboard.writeText(newShareLink).then(
                () => {
                    console.log("Clipboard write succeeded");
                },
                () => {
                    console.error("Clipboard write failed");
                }
            );
        })
        .catch(_ => {
            console.log("Error encountered when generating share link")
        })
    }, [cookbook.id]);

    const onExit = useCallback(() => {
        setIsVisible(false);
        setLoginName("");
        setPerm("READ");
        setShareLink("");
    }, [setIsVisible, setLoginName, setPerm]);

    const onClickAdd = useCallback(() => {
        if (!loginName) {
            return;
        }

        const csrfToken = getCsrfToken();

        fetch('/api/cookbook_perms',
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                cookbookId: cookbook.id.toString(),
                viewerName: loginName,
                viewerPerm: perm,
            })
        }).then(_ => {
            refreshCookbook(cookbook.id.toString());
            onExit();
        })
        .catch(_ => {
            console.log("Error encountered while trying to add viewer")
        })

    }, [cookbook.id, loginName, perm, refreshCookbook, onExit]);

    return <div 
        className={"viewers-add-container" + (isVisible ? " show" : "")}
        >
            <div className="recipe-edit-form-close" onClick={onExit} >
                <img src={xmarkSvg} alt="close" />
            </div>
            <div onClick={e => e.stopPropagation()}>
                <div className="viewers-add-form" >
                    <div className="form-title-container">
                        <div className="form-title">Add cookbook user</div>
                    </div>
                    <div className="row-input">
                        <div>
                            <label className={loginName ? "filled" : ""}>Email or username</label>
                        </div>
                        <div>
                            {isVisible && <input
                                type="text"
                                value={loginName}
                                placeholder="Email or username"
                                onChange={(e) => setLoginName(e.target.value)}
                                className={loginName ? " filled" : ""}
                                autoFocus={true}
                            />}
                        </div>
                    </div>
                    <div className="row-input select">
                        <select value={perm}
                            onChange={(e) => setPerm(e.target.value as TCookbookPerm)}>
                            <option key="label" value={0} disabled={true} >Select permission type</option>
                            {
                                cookbookPermOptions.map(o => 
                                    <option key={o.value} value={o.value}>{o.label}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="row-input" onClick={onGenerateLink}>
                        {shareLink ? shareLink : "Or... Click me to generate 15 minute share link"}
                    </div>
                </div>
                <div className="action-buttons">
                    <button 
                        className="save-edit" 
                        disabled={!loginName}
                        onClick={onClickAdd}
                    >
                       Add
                    </button>
                </div>
            </div>
    </div>;
}