import React, { createContext } from "react";
import { TCurrentUser, defaultUser } from "./types/user";

export type TUserContext = { 
    refreshCurrentUser: () => void; // Needed if we know an action would change current user (e..g, default recipe setting)
    loadingCurrentUser: boolean;
    logIn: (props: {loginName: string; password: string}) => void;
    logOut: (successCallback?: () => void) => void;
    signUp: (props: {firstName: string; email: string; password: string, inviteToken: string}) => void;
    showModal: false | {prompt: string, inviteToken?: string};
    setShowModal: (v: false | {prompt: string, inviteToken?: string}) => void; // prompt for explaining why user should log in
 } 
& TCurrentUser;

export const defaultUserContext = (): TUserContext => {
    return {...defaultUser(),
        refreshCurrentUser: () => undefined,
        logIn: (props) => undefined,
        logOut: () => undefined,
        signUp: (props) => undefined,
        loadingCurrentUser: false,
        showModal: false,
        setShowModal: (props) => undefined,
    };
};

export const UserContext = createContext<TUserContext>(defaultUserContext());

export const plannerStages = ["BOOKMARKING", "SHOPPING", "COOKING", "NOMMING"] as const;

export type TPlannerStage = typeof plannerStages[number];
export type TIngredientsCheck = Record<number, boolean>; // which ingredients have been checked (indexed)
export type TGroceries = Record<number, { // key = recipe id
    quantity: number; // how many portions
    shopping: TIngredientsCheck;
    prepping: TIngredientsCheck;
    cookingStep: number | null;
    cooked: boolean;
}>;

export type TPlanner = {
    stage: TPlannerStage;
    groceries: TGroceries; 
};

export type TPlannerContext = {
    currentPlanner: TPlanner;
    addToPlanner: (recipeId: number) => void;
    removeFromPlanner: (recipeId: number) => void;
    setPlannerStage: (stage: TPlannerStage) => void;
    toggleIngredientCheck: (props: { recipeId: number, ingredientIdx: number}) => void;
    reset: (recipeId?: number) => void;
    setCookingStep: (props: {recipeId: number, step: number | null}) => void;
    clearPlanner: () => void;
    toggleDone: (recipeId: number) => void;
};

export const defaultPlannerContext = (): TPlannerContext => {
    return {
        currentPlanner: { stage: "BOOKMARKING", groceries: {}},
        addToPlanner: (props) => undefined,
        removeFromPlanner: (props) => undefined,
        setPlannerStage: (props) => undefined,
        toggleIngredientCheck: (props) => undefined,
        reset: (props) => undefined,
        setCookingStep: (props) => undefined,
        clearPlanner: () => undefined,
        toggleDone: (props) => undefined,
    };
};

export const PlannerContext = createContext(defaultPlannerContext());

export type TSnackBar = {
    id: string; // each type of snack bar has a dedicated id
    message: string;
    action?: {
        label: string;
        callback: () => void;
    };
    displayTime?: number; // display time in milliseconds
};

export type TSnackBarContext = {
    snacks: TSnackBar[];
    addSnack: (s: TSnackBar) => void;
    popSnack: (id: string) => void;
};

const defaultSnackBarContext = (): TSnackBarContext => {
    return {
        snacks: [],
        addSnack: (props) => undefined,
        popSnack: (props) => undefined,
    }
};

export const SnackBarContext = createContext(defaultSnackBarContext());