export type TLoggedInUser = {
    username: string;
    email: string;
    firstName?: string;
    lastName?: string;
    profileImage?: string;
    defaultCookbook?: number;
    invites: {token: string, active: boolean}[]
}

export type TCurrentUser = {
    loggedIn: false;
} | {
    loggedIn: true;
    user: TLoggedInUser;
}

export const defaultUser = (): TCurrentUser => {
    return {loggedIn: false};
}

export type TUser = {
    first_name?: string;
    last_name?: string;
    username: string;
    setting: {
        image_src?: string;
    }
}

export type TViewer = {
    user: TUser;
    is_contributor: boolean;
    is_admin: boolean;
}