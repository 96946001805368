import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import './wakeLockToggle.scss';
import { useLocation } from "react-router-dom";

export const WakeLockToggle = () => {
    const location = useLocation();

    // Docs: https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API
    const wakeLockSupported: boolean = useMemo(() =>
        "wakeLock" in navigator, []);

    const [isWakeLocked, setIsWakeLocked] = useState(false); // lock toggle state
    const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null); // wake lock object

    const onToggleWakeLock = useCallback(() => {
        if (isWakeLocked) {
            setIsWakeLocked(false);
            if (wakeLock) {
                wakeLock.release().then(() => {
                    console.log("Released wake lock");
                    setWakeLock(null);
                }).catch(() => {
                    console.log("Unable to release wake lock");
                });
            }
            return;
        }

        setIsWakeLocked(true);
        navigator.wakeLock.request("screen")
            .then((w: WakeLockSentinel) => {
                console.log("Obtained wake lock");
                setWakeLock(w);
            }).catch(() => {
                console.log("Unable to obtain wake lock");
            });
    }, [isWakeLocked, setIsWakeLocked, wakeLock, setWakeLock]);

    useEffect(() => {
        if (!wakeLock) {
            return;
        }

        const listener = () => console.log("Wake Lock has been released!");
        wakeLock.addEventListener("release", listener);

        return () => {
            wakeLock.removeEventListener("release", listener);
        }
    }, [wakeLock, setWakeLock]);

    const containerRef = useRef(null);
    useEffect(() => {
        if (!wakeLock) {
            return;
        }
        return () => {
            if (containerRef.current) {
                return;
            }
            wakeLock.release().then(() => {
                console.log("Wake lock released due to unmount")
                setWakeLock(null);
        })};
    }, [wakeLock]);

    useEffect(() => {
        const listener = () => {
            if (!isWakeLocked || document.visibilityState !== "visible") {
                return;
            }
            navigator.wakeLock.request("screen")
                .then((w: WakeLockSentinel) => {
                    console.log("Re-obtained wake lock");
                    setWakeLock(w);
                }).catch(() => {
                    console.log("Unable to re-obtain wake lock")
                })
        };
        document.addEventListener("visibilitychange", listener);

        return () => document.removeEventListener("visibilitychange", listener);
    }, [isWakeLocked]);

    return <>
        {wakeLockSupported && <div className="wake-lock-container" ref={containerRef}>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" onClick={onToggleWakeLock} />
                    <span className="slider round"></span>
                </label>
            </div>
            <div>
                <span className="cook-mode-heading">Cook Mode </span>
                <span className="explanation">Keep device screen on</span>
            </div>
        </div>}
    </>;
};