import React, { useState } from "react";
import "./recipeTags.scss";
import { TRecipe, TTag } from "../types/recipe";

type TRecipeTagProps = {
    tag: TTag;
}

type TRecipeTagsProps = {
    recipeId: number;
    tags: Array<TTag>;
    fetchAndSetAllRecipes: (opts?: { successCallback: () => void}) => void;
    selectRecipe: (recipe: TRecipe) => void;
}

const RecipeTag = ({tag}: TRecipeTagProps) => {
    return <div className="recipe-tag-container">
        {`#${tag.name}`}
    </div>;
}

const namesToHashtagsString = (names: Array<string>) => {
    return names
        .map(n => `#${n}`)
        .join(' ');
}

const hashtagsStringToNames = (hashtagsString: string) => {
    return hashtagsString
        .split(`#`)
        .map((t) => t.trim())
        .filter((t) => t !== '')
}

export const RecipeTags = ({
    tags, 
    recipeId, 
    fetchAndSetAllRecipes, 
    selectRecipe}: TRecipeTagsProps) => {
    const [editMode, setEditMode] = useState(false);
    const [tagsInput, setTagsInput] = useState(namesToHashtagsString(tags.map(tag => tag.name)));

    const tagsChanged = tagsInput !== namesToHashtagsString(tags.map(tag => tag.name));

    const saveTags = () => {
        const tagNames = hashtagsStringToNames(tagsInput);

        const csrfToken = document.cookie.split(";").find(token => token.startsWith('csrftoken'))?.split('=')[1];
        fetch('/api/tagging', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                recipeId,
                tagNames
            })
        }).then(response => response.json())
        .then((data) => {
            fetchAndSetAllRecipes({
                successCallback: () => {
                    selectRecipe(data);
                    setTagsInput(namesToHashtagsString(tagNames));
                    setEditMode(false);
                }
            });
        })
    }

    const cancelTagsEdit = () => {
        setTagsInput(namesToHashtagsString(tags.map(tag => tag.name)));
        setEditMode(false);
    }

    return <div className={"recipe-tags-container"}>
        {!editMode && <div className={"saved-tags"}>
            {tags.map((tag) => <RecipeTag key={tag.name} tag={tag}/>)}
            <button className="button" onClick={() => setEditMode(true)}>
                {tags.length === 0 ? "Add Tags" : "Edit Tags"}
            </button>
        </div>}
        {editMode && <div className={"edit-tags-container"}>
            <textarea 
                className={"edit-tags-text"}
                placeholder="Tags to be added here! Prefix with hashtags, separated by spaces. E.g., `#hello #world`" 
                value={tagsInput}
                onChange={(e) => setTagsInput(e.target.value)}
                rows={3}
            >
                {tagsInput}
            </textarea>
            <div>
                <button className="button" onClick={saveTags} disabled={!tagsChanged}>Save Tags</button>
                <button className="button" onClick={cancelTagsEdit}>Cancel</button>
            </div>
        </div>}
    </div>;
}