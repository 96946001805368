import React, { MouseEvent, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import './cookbookTitle.scss';
import clockSvg from "../../assets/clock.svg";
import carrotSvg from "../../assets/carrot.svg";
import linkOutSvg from "../../assets/linkOut.svg";
import pencilSvg from "../../assets/pencil.svg";
import cookingSvg from "../../assets/chefHat.svg";
import { UserContext } from "../../appContext";
import { TCookbook } from "../../types/cookbook";
import { TUser } from "../../types/user";
import { TRecipeSortOrder, recipeSortOrderOptions } from "../../types/recipe";
import deckhouseUserImg from "../../assets/deckhouseUser.jpeg";
import plusSignBlueSvg from '../../assets/plusSignBlue.svg';
import { UserPicturePlaceholder } from "./UserPicturePlaceholder";

type TCookbookTitleProps = {
    cookbook: TCookbook;
    recipeContainerRef: MutableRefObject<HTMLDivElement | null>;
    setShowCookbookEdit: (showRecipeEdit: boolean) => void;
    setShowViewersAdd: (showViewersAdd: boolean) => void;
    userCanEditRecipes: boolean;
    setOpenAddRecipe: (v: boolean) => void;
    sortOrder: TRecipeSortOrder;
    setSortOrder: (v: TRecipeSortOrder) => void;
}

export const CookbookTitle = ({ 
    cookbook, 
    recipeContainerRef,
    setShowCookbookEdit,
    setShowViewersAdd,
    userCanEditRecipes,
    setOpenAddRecipe,
    sortOrder,
    setSortOrder } : TCookbookTitleProps) => {

    const userContext = useContext(UserContext);

    const userCanEditCookbook: boolean = useMemo(() => {
        if (!userContext.loggedIn || !cookbook) {
            return false;
        }

        return !!cookbook.viewers
            .find(v => v.is_admin && 
                v.user.username === userContext.user.username);
    }, [userContext, cookbook]);

    const topContributorsChart: Array<{user: TUser; count: number}> = useMemo(() => {
        const contributionCount: Record<string, { user: TUser; count: number}> = {};
        cookbook.viewers.forEach(v => {
            if (v.is_contributor) {
                contributionCount[v.user.username] = {user: v.user, count: 0};
            }
        });

        cookbook.recipes.forEach(r => {
            const username = r.uploader.username;
            if (username in contributionCount) {
                contributionCount[username].count += 1;
            }
        });

        return Object.values(contributionCount).sort((a, b) => {
            // Want to sort descending based on count of contributions for that user
            if (a.count > b.count) {
                return -1;
            }

            if (a.count < b.count) {
                return 1;
            }

            if (a.user.username < b.user.username) {
                return -1;
            }

            if (a.user.username > b.user.username) {
                return 1;
            }

            return 0;
        });
    }, [cookbook]);

    const topThreeContributors: Array<TUser & { name: string; }> = useMemo(() => {
        const topThree = topContributorsChart.length <= 3 ? topContributorsChart : topContributorsChart.slice(0, 4);
        return topThree.map(t => {
            return { 
                ...t.user,
                name: t.user.first_name ? t.user.first_name : t.user.username,
            };
        });
    }, [topContributorsChart]);

    const onOpenAttempt = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Only registered users can add new recipes."});
            return;
        }

        setOpenAddRecipe(true);
    }, [setOpenAddRecipe, userContext.loggedIn, userContext.setShowModal]);

    return <div className="cookbook-title-container" >
        <div className="title-line" >
            <div className="title-text-container">
                <div className="title-text">
                    {cookbook.title}
                </div>
            </div>
            {/* <div className={"cooking-mode" + (isCooking ? " focus" : "")} onClick={onClickIsCooking}>
                {!isCooking && <img src={cookingSvg} alt={"cooking mode"} className="icon"/>}
                {
                    recipe.image &&
                    <img src={`/recipe_images/${recipe.image}`} alt={recipe.title} />
                }
            </div> */}
        </div>
        <div className="cookbook-contributors-container">
            <div className="left">
                <div>
                    Created by {cookbook.creator.first_name || cookbook.creator.username}
                </div>
                <div>
                    Top contributors: {topThreeContributors.map(c => c.name).join(", ")}
                </div>
            </div>
            <div className="right">
                <div className="contributor-images">
                    {topThreeContributors.map(c => {
                        return <UserPicturePlaceholder 
                            key={c.username} 
                            user={c}
                            size={48}
                            textSize={28}
                        />;
                    })}
                </div>
                {userCanEditCookbook && <div className="add-viewer" onClick={() => setShowViewersAdd(true)}>
                    <img src={plusSignBlueSvg} alt="add viewer" />
                </div>}
            </div>
        </div>
        {
            userCanEditRecipes && <div className={"cookpage-add-button"} onClick={onOpenAttempt}>
                Add recipe
            </div>
        }
        <div className="title-data-container">
            <div className="title-data">
                <div className="recipe-count data">
                    <div>
                        {cookbook.recipes.length} Recipes
                    </div>
                </div>
                {/* {(userCanEditCookbook || !userContext.loggedIn) && <div className="edit data" onClick={() => setShowCookbookEdit(true)}>
                    <div>
                        <img src={pencilSvg} alt="edit cookbook" />
                    </div>
                    <div>
                        Manage
                    </div>
                </div>} */}
            </div>
            <div className="sort-by">
                <select name="sort-recipes" value={sortOrder} onChange={(e) => {
                    setSortOrder(e.target.value as TRecipeSortOrder)}
                }>
                    {recipeSortOrderOptions.map(o => <option key={o.value} value={o.value}>
                        {o.value === sortOrder ? o.display : o.label}
                    </option>)}
                </select>
            </div>
        </div>
        {/* {recipe.description && recipe.description !== "N/A" && !isCooking && <div className="title-description-container">
            <div className="heading">Description</div>
            <div className="title-description" onClick={() => setExpandDescription(!expandDescription)}>
                {description.text}{
                    expandDescription || description.isExpanded ? undefined :
                        <>
                            <span>... </span>
                            <span className="view-more">View More</span>
                        </>
                }
            </div>
        </div>} */}
    </div>
}