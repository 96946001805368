import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import plusSignSvg from '../../assets/plusSign.svg';
import './fab.scss';
import classNames from "classnames";
import { TPlannerStage } from "../../appContext";
import shoppingCartSvg from "../../assets/shoppingCart.svg";
import stoveSvg from "../../assets/stove.svg";
import cookieBiteSvg from "../../assets/cookieBite.svg";

type TStartNextStageFabProps = {
    className?: string;
    nextStage: TPlannerStage | null;
    promoteStage: () => void;
    show: boolean;
}

export const StartNextStageFab = ({className, nextStage, promoteStage, show}: TStartNextStageFabProps) => {
    const [expandFab, setExpandFab] = useState(true);

    useEffect(() => {
        if (!window) {
            return;
        }

        const scrollListener = () => {
            if (window.scrollY === 0) {
                // console.log("Scrolled to top");
                setExpandFab(true);
                return;
            }

            if (expandFab) {
                setExpandFab(false);
            }
        };

        window.addEventListener("scroll", scrollListener);

        return () => window.removeEventListener("scroll", scrollListener);
    }, [!window]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [nextStage]);

    const svg = useMemo(() => {
        if (!nextStage || nextStage === "BOOKMARKING") {
            return "";
        }

        if (nextStage === "SHOPPING") {
            return shoppingCartSvg;
        }

        if (nextStage === "COOKING") {
            return stoveSvg;
        }

       return cookieBiteSvg;
    }, [nextStage]);

    return <>
        <div 
        className="recipe-fab-container no-padding">
        {<div 
            className={classNames("recipe-fab", "in-planner", 
                expandFab ? "" : " collapsed", 
                show ? "show" : "")} 
            onClick={() => {
                if (!expandFab) {
                    setExpandFab(true);
                    return;
                }
                promoteStage();
            }}
        >
            {nextStage && svg && <div className="icon-container">
                <img src={svg} alt={`start ${nextStage.toLowerCase()}`}/>
            </div>}
            {nextStage && expandFab && <div>START {nextStage.toUpperCase()}</div>}
        </div>}
    </div>
    </>;
}