import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './cookbookPage.scss';
import { UserContext } from "../../appContext";
import { TCookbook } from "../../types/cookbook";
import { CookbookCover } from "./CookbookCover";
import { CookbookTitle } from "./CookbookTitle";
import { RecipesList } from "./RecipesList";
import { AddRecipeFab } from "./AddRecipeFab";
import { TRecipeSortOrder } from "../../types/recipe";
import { ViewersAddForm } from "./ViewersAddForm";
import { getPublicCookbookToken } from "../utils";

type TGalleryImage = { src: string };
type TGalleryImageResponse = {
    images: TGalleryImage[];
}


export const CookbookPage = () => {
    const { cookbookId } = useParams();
    const userContext = useContext(UserContext);

    const [cookbook, setCookbook] = useState<TCookbook | null>(null);
    const [openAddRecipe, setOpenAddRecipe] = useState(false);

    // useEffect(() => {
    //     if (!window) {
    //         return;
    //     }

    //     window.scrollTo(0, 0);
    // }, []);

    const refreshCookbook = useCallback((cookbookId: string) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch(`/api/cookbooks/${cookbookId}`, { headers: requestHeaders })
            .then(response => response.json())
            .then(data => {
                const newCookbook = data as TCookbook;
                if (data) {
                    setCookbook(newCookbook);
                }
            });
    }, []);

    useEffect(() => {
        if (!cookbookId) {
            return;
        }
        refreshCookbook(cookbookId);
    }, [cookbookId]);

    const hasAdminPower: boolean = useMemo(() => {
        if (!userContext.loggedIn || !cookbook) {
            return false;
        }

        return !!cookbook.viewers.
            find(v => v.is_admin && 
                v.user.username === userContext.user.username);
    }, [userContext, cookbook]);

    const [viewMode, setViewMode] = useState<"LIST" | "FLIP">("LIST");

    const recipeTitleRef = useRef<HTMLDivElement | null>(null);
    const recipeContainerRef = useRef<HTMLDivElement | null>(null);

    const [showViewersAdd, setShowViewersAdd] = useState(false);

    const [showCookbookEdit, setShowCookbookEditRaw] = useState(false);

    const setShowCookbookEdit = useCallback((v: boolean) => {
        if (v === false) {
            setShowCookbookEditRaw(v);
            return;
        }

        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Only registered users can edit cookbook information."});
            return;
        }

        setShowCookbookEditRaw(v);
    }, [setShowCookbookEditRaw, userContext.loggedIn, userContext.setShowModal]);

    const userCanEditRecipes: boolean = useMemo(() => {
        if (!userContext.loggedIn || !cookbook) {
            return false;
        }

        return !!cookbook.viewers
            .find(v => v.is_contributor &&
                v.user.username === userContext.user.username)
    }, [userContext, cookbook]);

    const [sortOrder, setSortOrder] = useState<TRecipeSortOrder>("NEW_TO_OLD_DATE");
    
    const currentLocation = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const querySortOrder = queryParams.get('order');

        if (!querySortOrder) {
            return;
        }

        setSortOrder(querySortOrder as TRecipeSortOrder)
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        if (!cookbookId) {
            return;
        }
        navigate(`/cookbook/${cookbookId}?order=${sortOrder}`, {replace: true, preventScrollReset: true})
    }, [navigate, sortOrder]);

    if (!cookbook) {
        return <div className="cookbook-page-container" key="cookbook-page">
        </div>
    }

    return <div className="cookbook-page-container" key="cookbook-page" ref={recipeContainerRef}>
        {
            userCanEditRecipes && <AddRecipeFab 
                className={"on-cookbook-page"}
                cookbookPage={{
                    cookbookId: cookbook.id,
                    title: cookbook.title,
                    open: openAddRecipe,
                    setOpen: setOpenAddRecipe
                }}/>
        }
        <CookbookCover 
            cookbook={cookbook} 
        />
        {cookbookId && <ViewersAddForm
            cookbook={cookbook}
            isVisible={showViewersAdd}
            setIsVisible={setShowViewersAdd}
            refreshCookbook={refreshCookbook}
        /> }
        <div className={"cookbook-page-content" + ((showCookbookEdit || showViewersAdd) ?  " editting" : "")}>
            <div className="top-notch-container">
                <div className="top-notch" />
            </div>
            <div className="cookbook-page-title" ref={recipeTitleRef}>
                <CookbookTitle
                    cookbook={cookbook} 
                    recipeContainerRef={recipeContainerRef} 
                    setShowCookbookEdit={setShowCookbookEdit}
                    setShowViewersAdd={setShowViewersAdd}
                    userCanEditRecipes={userCanEditRecipes}
                    setOpenAddRecipe={setOpenAddRecipe}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                />
            </div>
            <div className="cookbook-page-body">
                {viewMode === "LIST" && 
                    <RecipesList cookbook={cookbook} sortOrder={sortOrder}/>
                }
            </div>
        </div>
    </div>
}