import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Instructions } from "./Instructions";
import { Ingredients } from "./Ingredients";
import { TRecipe } from "../types/recipe";
import "./recipeContent.scss"
import { RecipePanels } from "./RecipePanel";
import { RecipeTags } from "./RecipeTags";
import { getCsrfToken, minutesToPrepTime } from "./utils";
import { PhotoGallery } from "./PhotoGallery";
import { ImageDropzone } from "./ImageDropzone";
import addImageSvg from "../assets/addImage.svg";
import viewGallerySvg from "../assets/viewGallery.svg";
import viewRecipeSvg from "../assets/viewRecipe.svg";

type TRecipeContentProps = {
  recipe?: TRecipe;
  isCookingMode: boolean;
  isVerticalSplit: boolean;
  allRecipes: Array<TRecipe>;
  selectRecipe: (r?: TRecipe) => void; 
  fetchAndSetAllRecipes: (opts?: { 
    selectRecipeId?: number; 
    successCallback?: () => void }) => void;
}

type TGalleryImage = { src: string };
type TGalleryImageResponse = {
  images: TGalleryImage[];
}

type TContentType = "RECIPE" | "GALLERY" | "UPLOAD";

type TDeleteType = "NONE" | "STAGE";

const GALLERY_COLUMNS = 3;

export const RecipeContent = ({
  recipe, 
  isCookingMode, 
  isVerticalSplit,
  allRecipes,
  selectRecipe,
  fetchAndSetAllRecipes}: TRecipeContentProps) => {
  useEffect(() => {
    const myDiv = document.getElementById('recipe-content');
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }, [recipe?.id]);

  const [gallery, setGallery] = useState<TGalleryImage[]>([]);
  const [contentType, setContentType] = useState<TContentType>("RECIPE");

  const galleryColumns: Array<TGalleryImage[]> = useMemo(() => {
    // note: each row actually ends up representing a column visually
    // generally, we want the lower indices from the full gallery image
    // array to display higher up on the screen
    const result: Array<Array<TGalleryImage>> = [];
    for (let i = 0; i < GALLERY_COLUMNS; i++) {
      result.push(new Array<TGalleryImage>());
    }

    gallery.forEach((image, idx) => {
      const column = idx % GALLERY_COLUMNS;
      result[column].push(image)
    });

    return result;
  }, [gallery]);

  const refreshPhotoGallery = useCallback((recipeId: number) => {
    fetch(`/api/recipe_images?recipeId=${recipeId}`)
      .then(response => response.json())
      .then(data => {
        const r = data as TGalleryImageResponse;
        setGallery(r.images);
      });
  }, [setGallery]);

  useEffect(() => {
    if (!recipe?.id) {
      return;
    }
    refreshPhotoGallery(recipe.id);
  }, [recipe?.id]);
  
  const onClickNavigation = (contentType: TContentType) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setContentType(contentType);
  };

  const onUploadAttemptComplete = useCallback((isNewCover: boolean) => {
    if (!recipe?.id) {
      return;
    }

    if (isNewCover) {
      fetchAndSetAllRecipes({
        selectRecipeId: recipe.id
      });
    }
    refreshPhotoGallery(recipe.id);

    if (contentType === "UPLOAD") {
      setContentType("GALLERY");
    }
  }, [fetchAndSetAllRecipes, 
    refreshPhotoGallery, 
    recipe?.id,
    contentType,
    setContentType]);

  const [deleteType, setDeleteType] = useState<TDeleteType>("NONE");
  const onDeleteType = useCallback((d: TDeleteType) => () => setDeleteType(d), [setDeleteType]);

  const onCommitDeletion = useCallback(() => {
    if (!recipe?.id) {
      return;
    }
    if (deleteType !== "STAGE") {
      console.log("We should not get in this state... let's commit delete only if it's been staged first")
      return;
    }

    const csrfToken = getCsrfToken();

    // todo @emikyu - use django-cleanup library to also remove images saved
    // associated with ImageField for recipe images
    fetch(`/api/recipes/${recipe.id}`, {
      method: "DELETE",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      }
    }).then(() => {
      fetchAndSetAllRecipes();
      selectRecipe(undefined);
      setDeleteType("NONE");
    });
  }, [deleteType, recipe?.id]);

  if (!recipe) {
    return <div 
    className={(isCookingMode ? "is-cooking " : " ") + "recipe-content"}
    id="recipe-content"
    >
      <RecipePanels selectRecipe={selectRecipe} recipes={allRecipes}/>
    </div>;
  }

  switch (contentType) {
    case 'RECIPE':
      return (<div
        className={(isCookingMode ? "is-cooking " : " ") + "recipe-content"}
        id="recipe-content"
      >
        <div>
          <div className="recipe-content-buttons">
            {gallery.length >= 1 && 
              <button onClick={onClickNavigation("UPLOAD")}>
                <img className="button-image" src={addImageSvg} />
              </button>}
            {gallery.length > 1 && 
              <button onClick={onClickNavigation("GALLERY")}>
                <img className="button-image" src={viewGallerySvg} />
              </button>}
          </div>
          <PhotoGallery
            recipeId={recipe.id}
            recipeImage={recipe.image}
            onUploadAttemptComplete={onUploadAttemptComplete}
          />
          <div className={(isCookingMode ? "is-cooking " : " ") + "recipe-content-text"}>
            <div>
              <h1>{recipe.title}</h1>
            </div>
            <div className="recipe-description-container">
              <RecipeTags
                recipeId={recipe.id}
                tags={recipe.tags}
                fetchAndSetAllRecipes={fetchAndSetAllRecipes}
                selectRecipe={selectRecipe}
              />
              <div>
                {recipe.description !== "N/A" && <div className={`recipe-description`}>{recipe.description}</div>}
                <div className="time-and-source">
                  {recipe.total_time && <div className="total-time">
                    Total Time: {minutesToPrepTime(recipe.total_time)}
                  </div>}
                  {recipe.source && <div>
                    <a href={recipe.source} target={"_blank"} className="source-link">Source</a>
                  </div>}
                </div>
              </div>
            </div>
            <div className={(isVerticalSplit ? "vertical-split " : " ") + "recipe-container"}>
              <Ingredients ingredients={recipe.ingredients} recipeId={recipe.id} />
              <Instructions text={recipe.instructions} recipeId={recipe.id} />
            </div>
            <div className="recipe-delete">
              {deleteType === "NONE" ? 
                <button onClick={onDeleteType("STAGE")}>
                  Delete Recipe
                </button> :
                <>
                  <button onClick={onDeleteType("NONE")}>
                      Cancel Delete
                  </button>
                  <button onClick={onCommitDeletion}>
                    Confirm Delete
                  </button>
                </>
              }
            </div>
          </div>
        </div>
      </div>);
    case 'UPLOAD':
      return <div
        className={(isCookingMode ? "is-cooking " : " ") + "recipe-content"}
        id="recipe-content"
      >
        <div className="recipe-content-buttons">
          <button onClick={onClickNavigation("GALLERY")}>
            <img className="button-image" src={viewGallerySvg} />
          </button>
          <button onClick={onClickNavigation("RECIPE")}>
            <img className="button-image" src={viewRecipeSvg} />
          </button>
        </div>
        <h1 className="recipe-content-title">{recipe.title}: Photo Upload</h1>
        <div>
          <ImageDropzone 
            recipeId={recipe.id}
            onUploadAttemptComplete={onUploadAttemptComplete}
            autoOverrideCover={!recipe.image}
            className="recipe-content-upload"
          />
        </div>
      </div>
    case 'GALLERY':
      return <div
        className={(isCookingMode ? "is-cooking " : " ") + "recipe-content"}
        id="recipe-content"
      >
        <div className="recipe-content-buttons">
          <button onClick={onClickNavigation("UPLOAD")}>
            <img className="button-image" src={addImageSvg} />
          </button>
          <button onClick={onClickNavigation("RECIPE")}>
            <img className="button-image" src={viewRecipeSvg} />
          </button>
        </div>
        <h1 className="recipe-content-title">{recipe.title}: Photo Gallery</h1>
        <div className="recipe-content-gallery">
          {
            galleryColumns.map((g, colIdx) => <div 
              style={
                {
                  width: `${Math.floor(98 / GALLERY_COLUMNS)}%`,
                }
              }
              key={`${recipe.id}_col_${colIdx}`}
            >
              {
                g.map(image =>
                  <img src={'/recipe_images/' + image.src} key={image.src} />)
              }
            </div>
          )}
        </div>
      </div>
  }
}