import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TRecipe } from "../../types/recipe";
import './recipePage.scss';
import { Ingredients } from "./Ingredients";
import { Instructions } from "./Instructions";
import { RecipeTitle } from "./RecipeTitle";
import { RecipeCover } from "./RecipeCover";
import { RecipePhotoGallery } from "./RecipePhotoGallery";
import { RecipePhotoUpload } from "./RecipePhotoUpload";
import { RecipeEditForm } from "./RecipeEditForm";
import { PlannerContext, UserContext } from "../../appContext";
import { getPublicCookbookToken } from "../utils";
import leftArrowSvg from "../../assets/leftArrowGray.svg";
import hamburgerSvg from "../../assets/hamburger.svg";
import classNames from "classnames";
import { PlannerIngredients } from "./PlannerIngredients";
import { TRecipeAbridged } from "../../types/cookbook";
import xmarkSvg from "../../assets/xmarkLightBlue.svg";
import checkSvg from "../../assets/circleCheck.svg";
import checkSolidSvg from "../../assets/circleCheckSolid.svg";

type TGalleryImage = { src: string };
type TGalleryImageResponse = {
    images: TGalleryImage[];
}

type TRecipeNav = "INGREDIENTS" | "INSTRUCTIONS";

export const RecipePage = ({inPlanner = false} : {inPlanner?: boolean}) => {
    const { recipeId } = useParams();
    const userContext = useContext(UserContext);
    const { currentPlanner, setPlannerStage, toggleDone } = useContext(PlannerContext);

    const [recipe, setRecipe] = useState<TRecipe | undefined>(undefined);

    useEffect(() => {
        if (!window) {
            return;
        }

        window.scrollTo(0, 0);
    }, []);

    const refreshRecipe = useCallback((recipeId: string) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch(`/api/recipes/${recipeId}`, {headers: requestHeaders})
            .then(response => response.json())
            .then(data => {
                const newRecipe = data as TRecipe;
                if (data) {
                    setRecipe(newRecipe);
                }
            });
    }, []);

    useEffect(() => {
        if (!recipeId) {
            return;
        }
        refreshRecipe(recipeId);
    }, [recipeId]);

    const recipeTitleRef = useRef<HTMLDivElement | null>(null);
    const recipeContainerRef = useRef<HTMLDivElement | null>(null);

    const isActivelyCooking = inPlanner && (currentPlanner.stage === "COOKING" || currentPlanner.stage === "SHOPPING");

    const [gallery, setGallery] = useState<TGalleryImage[]>([]);

    const refreshPhotoGallery = useCallback((recipeId: string, autoOpen = false) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch(`/api/recipe_images?recipeId=${recipeId}`, {headers: requestHeaders})
            .then(response => response.json())
            .then(data => {
                const r = data as TGalleryImageResponse;
                setGallery(r.images);

                if (autoOpen && r.images.length > 1) {
                    setShowPhotoGallery("FROM_UPLOAD");
                }
            });
    }, []);

    
    const currentStepRef = useRef<HTMLDivElement | null>(null);

    const [navWrap, setNavWrap] = useState<{
        nav: TRecipeNav;
        prevScrollY: Record<TRecipeNav, number>;
        isCooking: boolean;
        lastChanged: "NAV" | "COOKING" | null;
    }>({
        nav: "INGREDIENTS",
        prevScrollY: { // helps with tracking where the user last scrolled when navigating between ingredients & instructions
            INGREDIENTS: 0,
            INSTRUCTIONS: 0
        },
        isCooking: isActivelyCooking,
        lastChanged: null,
    });
    const { nav, isCooking, prevScrollY } = navWrap;

    const setNav = useCallback((n: "INGREDIENTS" | "INSTRUCTIONS") => {
        setNavWrap(prevNav => {
            const newNav = {...prevNav, prevScrollY: {...prevNav.prevScrollY}, lastChanged: "NAV" as const};
            newNav.nav = n;
            newNav.prevScrollY[prevNav.nav] = window.scrollY;
            return newNav;
        });
    }, [setNavWrap, 
        window.scrollY]);

    const setIsCooking = useCallback((c: boolean) => {
        setNavWrap(prevNav => {
            if (c === prevNav.isCooking) {
                return prevNav;
            }

            if (c === false) {
                window.scrollTo(0, 0);
            }

            return {
                nav: prevNav.nav,
                isCooking: c,
                prevScrollY: {
                    INGREDIENTS: 0,
                    INSTRUCTIONS: 0,
                },
                lastChanged: "COOKING" as const
            };
        });
    }, [setNavWrap]);

    const recipePageContentRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (navWrap.nav === "INSTRUCTIONS" && currentStepRef.current) {
            currentStepRef.current.scrollIntoView({ 
                block: "center", 
                inline: "nearest"
            });
        } else {
            const currentScrollY = window.scrollY;
            const navScrollY = navWrap.prevScrollY[navWrap.nav];
            const titleAtTopScrollY = (navWrap.isCooking || 
                (recipePageContentRef.current !== null && recipePageContentRef.current.offsetTop <= 0)) ? 
                0 : window.innerHeight * 0.5 - 32;

            // If we're not scrolled to the point where the recipe title becomes sticky,
            // then we do not need to adjust scroll as we will always show top of recipe
            // with some buffer
            if ((currentScrollY <= titleAtTopScrollY - 8) && !navWrap.isCooking) {
                return;
            }
            // Otherwise, scroll to previous position UNLESS it would make the title become not sticky
            // Keep title sticky at top
            if (navWrap.lastChanged === "COOKING") {
                const timeout = setTimeout(() => {
                    window.scrollTo(0, Math.max(navScrollY, titleAtTopScrollY));
                }, 900);

                return () => clearTimeout(timeout);
            } else {
                window.scrollTo(0, Math.max(navScrollY, titleAtTopScrollY));
            }
        }
    }, [navWrap, currentStepRef, window]);

    useEffect(() => {
        if (!recipeId) {
            return;
        }
        setNavWrap({
            nav: "INGREDIENTS" as const,
            isCooking: isActivelyCooking,
            prevScrollY: {
                INSTRUCTIONS: 0,
                INGREDIENTS: 0,
            },
            lastChanged: null
        });
        refreshPhotoGallery(recipeId);
    }, [recipeId, isActivelyCooking]);





    const [showPhotoGallery, setShowPhotoGallery] = useState<false | "FROM_RECIPE" | "FROM_UPLOAD">(false);
    const [showPhotoUpload, setShowPhotoUploadRaw] = useState<false | "FROM_RECIPE" | "FROM_GALLERY">(false);
    const [showRecipeEdit, setShowRecipeEditRaw] = useState(false);

    const setShowPhotoUpload = useCallback((v: false | "FROM_RECIPE" | "FROM_GALLERY") => {
        if (v === false) {
            setShowPhotoUploadRaw(v);
            return;
        }

        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Only registered users can upload recipe photos."});
            return;
        }

        setShowPhotoUploadRaw(v);
    }, [setShowPhotoUploadRaw, userContext.loggedIn, userContext.setShowModal]);

    const setShowRecipeEdit = useCallback((v: boolean) => {
        if (v === false) {
            setShowRecipeEditRaw(v);
            return;
        }

        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Only registered users can edit recipe information."});
            return;
        }

        setShowRecipeEditRaw(v);
    }, [setShowRecipeEditRaw, userContext.loggedIn, userContext.setShowModal]);

    const onUploadAttemptComplete = useCallback((isNewCover: boolean) => {
        if (!recipeId) {
            return;
        }

        if (isNewCover) {
            refreshRecipe(recipeId);
        }

        refreshPhotoGallery(recipeId, true)
        setShowPhotoUpload(false);
    }, [recipeId]);

    const navigate = useNavigate();

    // below is for planner related stuff
    const [allRecipes, setAllRecipes] = useState<Record<number, TRecipeAbridged> | null>(null);
    const fetchAndSetAllRecipes = useCallback((opts?: {
        successCallback?: () => void,
    }) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch('/api/recipes_metadata', { headers: requestHeaders })
            .then(response => response.json())
            .then(data => {
                const allNewRecipes = data as Array<TRecipeAbridged>;
                const result: Record<number, TRecipeAbridged> = {};

                if (allNewRecipes) {
                    allNewRecipes.forEach(r => {
                        result[r.id] = r;
                    })
                    setAllRecipes(result);
                }

                if (opts?.successCallback) {
                    opts.successCallback();
                }
            });
    }, [setAllRecipes]);

    const currentPlannerRecipes: Array<{recipe: TRecipeAbridged, count: number}> = useMemo(() => {
        if (!allRecipes) {
            return [];
        }
        return Object.entries(currentPlanner.groceries)
            .map(([recipeId, info]) => ({ recipe: allRecipes[parseInt(recipeId)], count: info.quantity}))
            .sort((a, b) => a.recipe.title < b.recipe.title ? -1 : 1);
    }, [allRecipes, currentPlanner.groceries]);

    useEffect(() => {
        if (!inPlanner) {
            return;
        }
        fetchAndSetAllRecipes();
    }, [userContext.loggedIn, inPlanner]);


    const [expandPlannerNav, setExpandPlannerNav] = useState(false);
    // above is for planner related stuff

    const checkComplete = useCallback((recipe: TRecipeAbridged) => {
        if (!recipe) {
            return false;
        }

        if (currentPlanner.stage !== "SHOPPING" && currentPlanner.stage !== "COOKING") {
            return false;
        }

        if (currentPlanner.stage === "COOKING") {
            return currentPlanner.groceries[recipe.id].cooked;
        }

        const totalChecked = Object.keys(currentPlanner.groceries[recipe.id].shopping).length;
        return totalChecked === recipe.ingredients.length;
    }, [currentPlanner.groceries, currentPlanner.stage, recipe]);


    useEffect(() => {
        if (!window) {
            return;
        }

        const scrollListener = () => {
            if (expandPlannerNav) {
                setExpandPlannerNav(false);
            }
        };

        window.addEventListener("scroll", scrollListener);

        return () => window.removeEventListener("scroll", scrollListener);
    }, [!window, expandPlannerNav, setExpandPlannerNav]);

    const nextIncompleteRecipe: number | null = useMemo(() => {
        if (!recipe) {
            return null;
        }

        const incompleteRecipes = currentPlannerRecipes.filter(r => !checkComplete(r.recipe) && r.recipe.id !== recipe.id);
        if (incompleteRecipes.length === 0) {
            return null;
        }

        const nextRecipe = incompleteRecipes.find(r => r.recipe.title > recipe.title);
        if (nextRecipe) {
            return nextRecipe.recipe.id;
        }

        return incompleteRecipes[0].recipe.id;
    }, [currentPlannerRecipes, checkComplete, recipe]);

    if (!recipe) {
        return <div className="recipe-page-container" key="recipe-page">
        </div>
    }

    return <div className={classNames("recipe-page-container", isActivelyCooking ? "in-planner" : undefined)} key={`recipe-page-${recipe.id}`} ref={recipeContainerRef}>
        {<RecipeCover 
            recipe={recipe} 
            gallery={gallery} 
            setShowPhotoGallery={setShowPhotoGallery}
            setShowPhotoUpload={setShowPhotoUpload}
            inPlanner={isActivelyCooking}
        />}
        {recipeId && !isActivelyCooking && <RecipePhotoGallery 
            recipeId={recipeId} 
            title={recipe.title}
            gallery={gallery} 
            isVisible={showPhotoGallery} 
            setIsVisible={setShowPhotoGallery}
            setShowPhotoUpload={setShowPhotoUpload}
            loggedIn={userContext.loggedIn}
        />}
        {recipeId && <RecipePhotoUpload
            recipeId={recipeId}
            title={recipe.title}
            isVisible={showPhotoUpload}
            setIsVisible={setShowPhotoUpload}
            setShowPhotoGallery={setShowPhotoGallery}
            onUploadAttemptComplete={onUploadAttemptComplete}
            autoOverrideCover={!recipe.image}
        />}
        {recipeId && <RecipeEditForm
            recipeId = {recipeId}
            recipe={recipe}
            isVisible={showRecipeEdit}
            setIsVisible={setShowRecipeEdit}
            refreshRecipe={refreshRecipe}
        /> }
        <div className={"recipe-page-content" + (isCooking ? " cooking" : "") + (showPhotoGallery || showPhotoUpload ? " photoing" : "") + (showRecipeEdit ?  " editting" : "")}
            onClick={() => setExpandPlannerNav(false)}
            ref={recipePageContentRef}
            >
            {!isActivelyCooking && <div className="top-notch-container">
                <div className="top-notch" />
            </div>}
            <div className={classNames("recipe-page-title", isActivelyCooking ? "in-planner" : undefined)} ref={recipeTitleRef}>
                {isActivelyCooking && expandPlannerNav && <div className="modal" onClick={() => setExpandPlannerNav(false)}/>}
                {isActivelyCooking && <div className="planner-nav">
                    <div className="planner-back" onClick={() => navigate(-1)}>
                        <img src={leftArrowSvg} alt="Go back" />
                        <div>
                            {currentPlanner.stage}
                        </div>
                    </div>
                    <div className="image-wrap" onClick={(e) => {
                        e.stopPropagation();
                        setExpandPlannerNav(!expandPlannerNav);
                    }}>
                        <div className="check-mark">
                            <img src={checkComplete(recipe) ? checkSolidSvg : checkSvg } alt="update status" />
                        </div>
                        <div className="hamburger">
                            <img src={hamburgerSvg} alt="recipes menu open" />
                        </div>
                        {<div className={classNames("planner-nav-inner", expandPlannerNav ? "show" : undefined)}>
                            <div className="recipe-image-container">
                                <div className="check-mark">
                                    <img src={checkComplete(recipe) ? checkSolidSvg : checkSvg } alt="update status" />
                                </div>
                                <div className="x-mark">
                                    <img src={xmarkSvg} alt="recipes menu close" />
                                </div>
                                <div className="gradient"/>
                                {recipe.image ? <img src={`/recipe_images/${recipe.image}`} alt={recipe.title} /> : recipe.title[0].toUpperCase() }
                            </div>
                            {currentPlannerRecipes.filter(r => r.recipe.id !== recipe.id).map(r => {
                                return <div
                                    className={classNames("planner-nav-row", checkComplete(r.recipe) ? "complete" : "")}
                                    key={r.recipe.id}
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        setExpandPlannerNav(false);
                                        navigate(`/planner/recipe/${r.recipe.id}`, { replace: true});
                                }}>
                                    <div className="check-mark">
                                        <img src={checkComplete(r.recipe) ? checkSolidSvg : checkSvg } alt="update status" />
                                    </div>
                                    {r.recipe.image ? <img src={`/recipe_images/${r.recipe.image}`} alt={r.recipe.title} /> : r.recipe.title[0].toUpperCase() }
                                </div>
                            })}
                        </div>}
                        <div className="recipe-image-container">
                            <div className="gradient"/>
                            {recipe.image ? <img src={`/recipe_images/${recipe.image}`} alt={recipe.title} /> : recipe.title[0].toUpperCase() }
                        </div>
                    </div>
                </div>}
                <RecipeTitle 
                    recipe={recipe} 
                    recipeContainerRef={recipeContainerRef} 
                    isActivelyCooking={isActivelyCooking}
                    isCooking={isCooking} 
                    setIsCooking={setIsCooking}
                    setShowRecipeEdit={setShowRecipeEdit}
                />
                {/* Note: We want nav to be here so it also becomes sticky with the title */}
                <div className={classNames("recipe-page-nav", isActivelyCooking ? "in-planner" : undefined)} >
                    <div onClick={() => setNav("INGREDIENTS")}
                        className={"nav-ingredients" + (nav === "INGREDIENTS" ? " selected" : "")}>
                        <div>Ingredients</div>
                        <div className="nav-marker" />
                    </div>
                    <div onClick={() => setNav("INSTRUCTIONS")}
                        className={"nav-instructions" + (nav === "INSTRUCTIONS" ? " selected" : "")}>
                        <div>Instructions</div>
                        <div className="nav-marker" />
                    </div>
                </div>
            </div>
            <div className={classNames("recipe-page-body", isCooking ? "cooking" : undefined)}>
                {<div className={"recipe-page-ingredients" + (nav === "INGREDIENTS" ? " show" : "")}>
                    { isActivelyCooking && 
                        currentPlanner.stage === "SHOPPING" && 
                        checkComplete(recipe) && 
                        <div className="next-recipe" onClick={() => {
                            if (nextIncompleteRecipe) {
                                navigate(`/planner/recipe/${nextIncompleteRecipe}`, {replace: true});
                                return;
                            }

                            setPlannerStage('COOKING');
                            navigate(`/planner`);
                        }}>
                            {nextIncompleteRecipe ? "Shop for the next recipe" : "Start cooking"}
                        </div>}
                    { isActivelyCooking ?
                        <PlannerIngredients 
                        recipeId={recipe.id} 
                        ingredients={recipe.ingredients}
                        className={"on-recipe-page"}/> :
                        <Ingredients recipeId={recipe.id} 
                        ingredients={recipe.ingredients} />
                    }
                </div>}
                {<div className={"recipe-page-instructions" + (nav === "INSTRUCTIONS" ? "show" : "")} >
                    <Instructions 
                        isActivelyCooking={isActivelyCooking} 
                        recipeId={recipe.id} 
                        text={recipe.instructions} 
                        currentStepRef={currentStepRef}
                    />
                    { isActivelyCooking && 
                        currentPlanner.stage === "COOKING" &&
                        <div className="next-recipe cooking-stage" onClick={() => {
                            if (!checkComplete(recipe)) {
                                toggleDone(recipe.id);
                            }

                            // window.scrollTo(0, 0);
                            // setNav("INGREDIENTS");

                            if (nextIncompleteRecipe) {
                                navigate(`/planner/recipe/${nextIncompleteRecipe}`, {replace: true});
                                return;
                            }
                            
                            setPlannerStage('NOMMING');
                            navigate(`/planner`);
                        }}>
                            {!checkComplete(recipe) ? 
                                (nextIncompleteRecipe ? "Mark as done & Go to next recipe" : "Mark as done & Start nomming") : 
                                (nextIncompleteRecipe ? "Go to the next recipe" : "Start nomming")}
                        </div>}
                </div>}
            </div>
        </div>
    </div>
}