import { TRecipeAbridged } from "../types/cookbook";

export const minutesToPrepTime = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes} mins`;
  }

  const hours = Math.floor(minutes / 60);
  const extraMinutes = minutes - hours * 60;
  return `${hours} hr${hours < 2 ? '' : 's'}` + (extraMinutes > 0 ? ` ${extraMinutes} mins` : '');
}

export const getCsrfToken = () => {
  return document.cookie.split(";").
    find(token => token.startsWith('csrftoken'))?.
    split('=')[1];
}

export const getPublicCookbookToken = () => {
  return sessionStorage.getItem("cookbookToken") || undefined;
}

// generic debounce function
export const debounce = (fn: Function, timeoutMs = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => { fn.apply(this, args); }, timeoutMs);
  };
}


// regex checker for valid url string
export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export const rankedTags = (recipes: TRecipeAbridged[]): string[] => {
  if (recipes.length === 0) {
    return [];
  }

  const tagCount: Record<string, number> = {};

  recipes.forEach(r => {
    r.tags.forEach(t => {
      const tagName = t.name.toLowerCase();
      if (!(tagName in tagCount)) {
        tagCount[tagName] = 0;
      }

      tagCount[tagName] += 1;
    });
  });

  const tagCountArray = Object.entries(tagCount).map(([k, v]: [string, number]) => {
    return [k, v];
  }).sort((a, b) => {
    // reverse sort to get most popular tags first
    if (a[1] > b[1]) {
      return -1;
    }
    if (a[1] < b[1]) {
      return 1;
    }
    return 0;
  })
  
  const result = tagCountArray.map(e => e[0]) as string[];
  // console.log(tagCountArray);

  return result;
}

export const stringToColor = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }
  
  return color;
}