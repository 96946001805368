import React, { useContext, useEffect, useMemo, useState } from "react";
import "./snackBar.scss";
import { PlannerContext, SnackBarContext, TSnackBar } from "../../appContext";
import classNames from "classnames";
import { matchPath, useLocation } from "react-router-dom";

// https://m2.material.io/components/snackbars

type TSnackBarProps = TSnackBar;

const DEFAULT_DISPLAY_TIME = 5 * 1000;
const DISAPPEARING_TIME = 1 * 1000;

const Snack = ({id, message, action, displayTime = DEFAULT_DISPLAY_TIME}: TSnackBarProps) => {
    const { popSnack } = useContext(SnackBarContext);
    const [isDisappearing, setIsDisappearing] = useState(false);
    const [isAppearing, setIsAppearing] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsAppearing(true);
        }, 50);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsDisappearing(true);
            const innerTimeout = setTimeout(() => {
                popSnack(id);
                // console.log("inner timeout for", id);
                clearInnerTimeout();
            }, DISAPPEARING_TIME);

            const clearInnerTimeout = () => {
                clearTimeout(innerTimeout);
            };

            // console.log("outer timeout for", id);
        }, displayTime - DISAPPEARING_TIME);

        return () => clearTimeout(timeout);
    }, [message, action, popSnack, setIsDisappearing, displayTime]);

    return <div className={classNames("snack-bar-content", isAppearing ? "appearing" : "", isDisappearing ? "disappearing" : "")}>
        <div className="snack-bar-message">
            {message}
        </div>
        {action && <div className="snack-bar-action" onClick={() => {
            action.callback();
            popSnack(id);
        }}>
            {action.label}
        </div>}
    </div>
}

const fabPaths = [
    '/',
    '/search'
];

export const SnackBar = () => {
    const { snacks } = useContext(SnackBarContext);
    const location = useLocation();
    const { currentPlanner } = useContext(PlannerContext);

    const pageContainsFab = useMemo(() => {
        // check for match for any page that always has fab
        if (fabPaths.find(p => matchPath(p, location.pathname))) {
            return true;
        }

        if (matchPath('/planner', location.pathname)) {
            return Object.keys(currentPlanner.groceries).length > 0 && currentPlanner.stage !== "NOMMING";
        }

        return false;
    }, [location, currentPlanner]);

    return <div className={classNames("snack-bar-container", pageContainsFab ? "contains-fab" : "")}>
        {snacks.map(snack => <Snack key={snack.id} {...snack} />)}
    </div>
} 