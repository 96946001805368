import React, { ChangeEvent, ChangeEventHandler, useCallback, useRef, useState } from "react";
import searchSvg from "../../assets/search.svg";
import slidersSvg from "../../assets/slidersPurple.svg";
import './searchInput.scss';
import { useNavigate } from "react-router-dom";
import { debounce, minutesToPrepTime } from "../utils";
import classNames from "classnames";

type TSearchInputProps = {
    autoFocus?: boolean;
    updateRecipesFilter?: (filter: string, opts?: { ingredientsLimit: number; timeLimit: number }) => void;
    location: "HOME" | "SEARCH";
    maxIngredientsCount?: number;
    maxCookingTime?: number;
}

export const SearchInput = ({ autoFocus, updateRecipesFilter, location, maxIngredientsCount, maxCookingTime }: TSearchInputProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState("");
    const [ingredientsLimit, setIngredientsLimit] = useState<number | null>(null);
    const [timeLimit, setTimeLimit] = useState<number | null>(null);

    const onInputActionValueChange: ChangeEventHandler = useCallback(debounce((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? "";
        setInputValue(value);

        if (!updateRecipesFilter) {
            return;
        }

        const opts = {
            ingredientsLimit: ingredientsLimit ?? maxIngredientsCount ?? 100,
            timeLimit: timeLimit ?? maxCookingTime ?? 2880
        };

        updateRecipesFilter(value, opts);
    }), [updateRecipesFilter, 
        ingredientsLimit, 
        maxIngredientsCount, 
        timeLimit, 
        maxCookingTime]);

    const onTimeLimitChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const x = 0;
        const y = 180;
        const z = maxCookingTime ?? 540;

        const a = (x * z - y * y) / (x - 2 * y + z);
        const b = Math.pow(y - x, 2) / (x - 2 * y + z);
        const c = 2 * Math.log((z - y) /(y - x));

        const newTimeLimit = Math.floor((a + b * Math.exp(c * parseFloat(e.target.value)))/15) * 15;
        setTimeLimit(newTimeLimit);
        // console.log(newTimeLimit)

        if (!updateRecipesFilter) {
            return;
        }

        const opts = {
            ingredientsLimit: ingredientsLimit ?? maxIngredientsCount ?? 100,
            timeLimit: newTimeLimit ?? maxCookingTime ?? 2880
        };

        updateRecipesFilter(inputValue, opts);
    }, [maxCookingTime, 
        setTimeLimit, 
        ingredientsLimit, 
        maxIngredientsCount, 
        inputValue,
        updateRecipesFilter]);

    const onIngredientsLimitChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newIngredientsLimit = parseInt(e.target.value ?? "0");
        setIngredientsLimit(newIngredientsLimit);

        if (!updateRecipesFilter) {
            return;
        }

        const opts = {
            ingredientsLimit: newIngredientsLimit ?? maxIngredientsCount ?? 100,
            timeLimit: timeLimit ?? maxCookingTime ?? 2880
        };

        updateRecipesFilter(inputValue, opts);
    }, [setIngredientsLimit,
        updateRecipesFilter,
        maxIngredientsCount,
        timeLimit,
        maxCookingTime,
        inputValue]);

    const [showSliders, setShowSliders] = useState(false);

    return <div className="search-input-container">
        <div className="search-bar-container">
            <div className="search-bar">
                <div
                    className={"selected action"}
                >
                    <img src={searchSvg} />
                </div>
                <input
                    ref={inputRef}
                    className="search-input"
                    type="text"
                    placeholder={"Search your recipes"}
                    onChange={onInputActionValueChange}
                    onClick={(e) => {
                        e.preventDefault();
                        if (location === "HOME") {
                            navigate('/search');
                            // console.log("search time!");
                        }
                    }}
                    autoFocus={autoFocus}
                />
            </div>
            <div 
                className={"filter"}
                onClick={(e) => {
                    if (location !== "SEARCH") {
                        navigate('/search');
                        return;
                    }
                    e.stopPropagation();
                    setShowSliders(!showSliders);
                }}>
                <img src={slidersSvg} alt="filter" />
            </div>
        </div>
        {
            location === "SEARCH" && maxIngredientsCount && maxCookingTime && maxIngredientsCount > 0 && maxCookingTime > 0 &&
            <div className={classNames("search-sliders", showSliders ? "visible" : "")}>
                <div className="slider-container">
                    <div className="slider-input">
                        <input type="range" className={"title-bar-slider"}
                            min={1}
                            max={maxIngredientsCount}
                            onChange={onIngredientsLimitChange}/>
                    </div> 
                    <div className="slider-label">
                        {ingredientsLimit === null || ingredientsLimit === maxIngredientsCount ?
                        "∞ ingredients" :
                        "≤ " + (ingredientsLimit ?? maxIngredientsCount) + " ingredients"}
                    </div>
                </div>
                <div className="slider-container">
                    <div className="slider-input">
                        <input type="range" className={"title-bar-slider"}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={onTimeLimitChange} />
                    </div>
                    <div className="slider-label">
                        <div>
                            {timeLimit === null || timeLimit === maxCookingTime ? "∞ time" :
                                "≤ " + minutesToPrepTime(timeLimit ?? maxCookingTime)}
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>;
}