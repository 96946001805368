import React from 'react';
import './userPicturePlaceholder.scss';
import { TLoggedInUser, TUser } from '../../types/user';
import { stringToColor } from '../utils';

type TUserPicturePlaceholderProps = {
    user?: TUser | TLoggedInUser;
    size: number;
    textSize: number;
    className?: string;
    onClick?: () => void;
    preview?: string;
}

export const UserPicturePlaceholder = ({user, size, textSize, className, onClick, preview}: TUserPicturePlaceholderProps) => {
    const image = !user ? undefined : "setting" in user ? user.setting.image_src : user.profileImage;
    const firstName = !user ? "Guest" : "setting" in user ? user.first_name : user.firstName;
    const lastName = !user ? "" : "setting" in user ? user.last_name : user.lastName;
    const username = user?.username ?? "guest";


    if (image || preview) {
        return <img 
            onClick={onClick}
            className="user-picture"
            src={preview || `/recipe_images/${image}`} alt="user picture"
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
        />
    }

    const placeholderColor = stringToColor(username);
    const placeholderText = firstName ? 
        (firstName[0] + (lastName ? lastName[0] : "")).toUpperCase() :
        username[0].toUpperCase();

    return <div className="user-image-placeholder" 
        onClick={onClick}
        style={{
            width: `${size}px`,
            height: `${size}px`,
            fontSize: `${textSize}px`,
            backgroundColor: placeholderColor,
        }}>
            <div className="inner">
                {placeholderText}
            </div>
    </div>
}