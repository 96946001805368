import React, { useCallback, useContext, useEffect, useState } from "react";
import './userModal.scss';
import { SnackBarContext, UserContext } from "../../appContext";

export const UserModal = () => {
    const {showModal, setShowModal, signUp, logIn, loggedIn, loadingCurrentUser} = useContext(UserContext);
    const { addSnack } = useContext(SnackBarContext);
    const prompt = showModal ? showModal.prompt : undefined;
    const [formType, setFormTypeRaw] = useState<"SIGN_UP" | "LOG_IN">("SIGN_UP");

    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [inviteToken, setInviteToken] = useState("");

    const [loginName, setLoginName] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [showLoginPassword, setShowLoginPassword] = useState(false);

    const setFormType = useCallback((v: "SIGN_UP" | "LOG_IN") => {
        setShowPassword(false);
        setShowLoginPassword(false);
        setFormTypeRaw(v);
    }, [setFormTypeRaw, setShowPassword, setShowLoginPassword]);

    useEffect(() => {
        setFirstName("");
        setEmail("");
        setPassword("");
        setLoginName("");
        setLoginPassword("");
        setInviteToken("");
    }, [loggedIn]);

    const onExit = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setShowModal(false);
        setFormType("SIGN_UP");
        setFirstName("");
        setEmail("");
        setPassword("");
        setLoginName("");
        setLoginPassword("");
        // setInviteToken(""); @emikyu - TODO: decide if we want to keep this state
    }, [setShowModal]);

    useEffect(() => {
        if (showModal && showModal.inviteToken) {
            if (loggedIn) {
                addSnack({
                    id: `sign-up-blocked-${showModal.inviteToken}`,
                    message: 'Unable to sign up for new account while logged in.'
                });
                setShowModal(false);
                return;
            }
            setInviteToken(showModal.inviteToken);
        }
    }, [showModal, loggedIn, setShowModal]);

    if (loggedIn || loadingCurrentUser) {
        return null;
    }

    return <div className={"user-modal-container" + (showModal ? " show" : "")}
        onClick={onExit}>
        {/* NOTE: showModal is added to condition trivially to trick auto focus into working */}
        {formType === "SIGN_UP" && showModal && <div className="user-modal-content" onClick={e => e.stopPropagation()}>
            <div className="user-sign-up-form">
                <div className="form-title-container">
                    <div className="form-title">
                        Om nom nom...
                    </div>
                </div>
                <div className="form-subtitle">
                    {(prompt ? prompt : "Hello and welcome.") + " Sign up today to track and share your favorite recipes!"}
                </div>
                <div className="row-input">
                    <div>
                        <label className={firstName ? "filled" : ""}>First name</label>
                    </div>
                    <div>
                        <input
                            type="text"
                            value={firstName}
                            placeholder="First name"
                            onChange={(e) => setFirstName(e.target.value)}
                            className={firstName ? " filled" : ""}
                            autoFocus={true}
                        />
                    </div>
                </div>
                <div className="row-input">
                    <div>
                        <label className={email ? "filled" : ""}>Email</label>
                    </div>
                    <div>
                        <input
                            type="email"
                            value={email}
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                            className={email ? " filled" : ""}
                        />
                    </div>
                </div>
                <div className="row-input">
                    <div>
                        <label className={password ? "filled" : ""}>Password</label>
                    </div>
                    <div className={"password-input-container" + (password ? " filled" : "")}>
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            className={"password-input" + (password ? " filled" : "")}
                        />
                        <div className="show-toggle" onClick={() => setShowPassword(!showPassword)}>
                            <div>
                                {showPassword ? "Hide" : "Show"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-input">
                    <div>
                        <label className={inviteToken ? "filled" : ""}>Invite token</label>
                    </div>
                    <div>
                        <input
                            type="text"
                            value={inviteToken}
                            placeholder="Invite token"
                            onChange={(e) => setInviteToken(e.target.value)}
                            className={inviteToken ? " filled" : ""}
                        />
                    </div>
                </div>
                <div className="log-in-prompt">
                    <div onClick={() => setFormType("LOG_IN")}>
                        Already have an account? <span className="log-in-highlight">Log in</span>
                    </div>
                </div>
            </div>
            <div className="action-buttons">
                    <button 
                        className="sign-up" 
                        disabled={!firstName || !email || !password}
                        onClick={() => signUp({firstName, email, password, inviteToken})}
                    >
                        Register
                    </button>
                </div>
        </div>}
        {formType === "LOG_IN" && showModal && <div className="user-modal-content" onClick={e => e.stopPropagation()}>
            <div className="user-log-in-form">
                <div className="form-title-container">
                    <div className="form-title">
                        Welcome back!
                    </div>
                </div>
                <div className="form-subtitle">
                    Enter your details below to sign in.
                </div>
                <div className="row-input">
                    <div>
                        <label className={loginName ? "filled" : ""}>Email or username</label>
                    </div>
                    <div>
                        <input
                            type="text"
                            value={loginName}
                            placeholder="Email or username"
                            onChange={(e) => setLoginName(e.target.value)}
                            className={loginName ? " filled" : ""}
                            autoFocus={true}
                        />
                    </div>
                </div>
                <div className="row-input">
                    <div>
                        <label className={loginPassword ? "filled" : ""}>Password</label>
                    </div>
                    <div className={"password-input-container" + (loginPassword ? " filled" : "")}>
                        <input
                            type={showLoginPassword ? "text" : "password"}
                            value={loginPassword}
                            placeholder="Password"
                            onChange={(e) => setLoginPassword(e.target.value)}
                            className={"password-input" + (loginPassword ? " filled" : "")}
                        />
                        <div className="show-toggle" onClick={() => setShowLoginPassword(!showLoginPassword)}>
                            <div>
                                {showLoginPassword ? "Hide" : "Show"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sign-up-prompt">
                    <div onClick={() => setFormType("SIGN_UP")}>
                        Don't have an account? <span className="sign-up-highlight">Sign up</span>
                    </div>
                </div>
            </div>
            <div className="action-buttons">
                    <button 
                        className="log-in" 
                        disabled={!loginName || !loginPassword}
                        onClick={() => logIn({loginName, password: loginPassword})}
                    >
                        Log in
                    </button>
                </div>
        </div>}
    </div>
};