import React from "react";
import './toggle.scss';
import classNames from "classnames";

type TToggleProps = {
    onToggle: () => void;
    checked?: boolean;
    heading?: string;
    explanation?: string;
    className?: string;
}

export const Toggle = ({onToggle, heading, explanation, className, checked}: TToggleProps) => {
    return <div className={classNames("switch-toggle-container", className)}>
        <div className="switch-container">
            <label className="switch">
                <input type="checkbox" onClick={onToggle} onChange={() => undefined} checked={checked}/>
                <span className="slider round"></span>
            </label>
        </div>
        <div>
            {heading && <span className="heading">{heading} </span>}
            {explanation && <span className="explanation">{explanation}</span>}
        </div>
    </div>;
};