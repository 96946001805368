import React, { useCallback, useContext, useState } from "react";
import './recipeEditForm.scss';
import { TRecipe } from "../../types/recipe";
import { getCsrfToken } from "../utils";
import { useNavigate } from "react-router-dom";
import xmarkSvg from '../../assets/xmark.svg';
import { SnackBarContext } from "../../appContext";

type TRecipeEditFormProps = {
    recipeId: string;
    recipe: TRecipe;
    isVisible: boolean;
    setIsVisible: (v: boolean) => void;
    refreshRecipe: (recipeId: string) => void;
}

const namesToHashtagsString = (names: Array<string>) => {
    return names
        .map(n => `#${n}`)
        .join(' ');
}

const hashtagsStringToNames = (hashtagsString: string) => {
    return hashtagsString
        .split(`#`)
        .map((t) => t.trim())
        .filter((t) => t !== '')
}

export const RecipeEditForm = ({
    recipeId,
    recipe,
    isVisible,
    setIsVisible,
    refreshRecipe
}: TRecipeEditFormProps) => {
    const [title, setTitle] = useState(recipe.title);
    const [tagsInput, setTagsInput] = useState(namesToHashtagsString(recipe.tags.map(tag => tag.name)));
    const [formType, setFormType] = useState<"EDIT" | "DELETE">("EDIT");

    const tagsChanged = tagsInput !== namesToHashtagsString(recipe.tags.map(tag => tag.name));
    const titleChanged = title !== recipe.title;

    const { addSnack } = useContext(SnackBarContext);
    
    const onClickSave = useCallback(() => {
        if (!tagsChanged && !titleChanged) {
            return;
        }

        const csrfToken = getCsrfToken();


        const tagNames = hashtagsStringToNames(tagsInput);

        const body = JSON.stringify({
            title,
            ...(tagsChanged ? {tags: tagNames} : {}),
        });

        fetch(
            `/api/recipes/${recipeId}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",

            },
            body
        }).then(_ => {
            refreshRecipe(recipeId);
            addSnack({
                id: `recipe-edit-success-${recipeId}-${body}`,
                message: `Changes to your recipe have been saved.`
            });
            setIsVisible(false);
        }).catch(_ => console.log("Error when updating recipes"));

    }, [tagsInput, 
        title, 
        recipe, 
        recipeId, 
        refreshRecipe, 
        setIsVisible,
        addSnack]);

    const onExit = useCallback(() => {
        setIsVisible(false);
        setTitle(recipe.title);
        setFormType("EDIT");
        setTagsInput(namesToHashtagsString(recipe.tags.map(tag => tag.name)))
    }, [setIsVisible, setTitle, recipe.title, setTagsInput, recipe.tags]);

    const navigate = useNavigate();

    const onConfirmDelete = useCallback(() => {
        if (formType !== "DELETE") {
            console.log("We should not get in this state... let's commit delete only if it's been staged first")
            return;
        }

        const csrfToken = getCsrfToken();
        // todo @emikyu - use django-cleanup library to also remove images saved
        // associated with ImageField for recipe images
        fetch(`/api/recipes/${recipeId}`, {
            method: "DELETE",
            headers: {
                "X-CSRFToken": csrfToken ?? "",
              }
        }).then(() => {
            navigate('/');
            addSnack({
                id: `delete-recipe-success-${recipeId}`,
                message: `Recipe for '${recipe.title}' was deleted.`
            });
        });
    }, [formType, recipeId, navigate, recipe.title]);

    return <div 
        className={"recipe-edit-container" + (isVisible ? " show" : "")}
        >
            <div className="recipe-edit-form-close" onClick={onExit} >
                <img src={xmarkSvg} alt="close" />
            </div>
            {formType === "EDIT" && <div onClick={e => e.stopPropagation()}>
                <div className="recipe-edit-form" >
                    <div className="form-title-container">
                        <div className="form-title">Edit recipe</div>
                        <div className="delete" onClick={() => setFormType("DELETE")}>
                            Delete recipe
                        </div>
                    </div>
                    <div className="row-input">
                        <div>
                            <label className={title ? "filled" : ""}>Title</label>
                        </div>
                        <div>
                            <input
                                type="text"
                                value={title}
                                placeholder="Recipe title"
                                onChange={(e) => setTitle(e.target.value)}
                                className={title ? " filled" : ""}
                            />
                        </div>
                    </div>
                    <div className="row-textarea">
                        <div>
                            <label className={tagsInput ? "filled" : ""}>Tagging</label>
                        </div>
                        <div>
                            <textarea 
                                rows={5} 
                                placeholder={"Add #tags here (e.g., #lunch #seafood)"} 
                                value={tagsInput}
                                onChange={(e) => setTagsInput(e.target.value)}
                                className={tagsInput ? " filled" : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="action-buttons">
                    <button 
                        className="save-edit" 
                        disabled={!titleChanged && !tagsChanged}
                        onClick={onClickSave}
                    >
                        Save changes
                    </button>
                </div>
            </div>}

            {formType === "DELETE" && <div onClick={e => e.stopPropagation()}>
                <div className="recipe-edit-form delete" >
                    <div className="form-title-container">
                        <div className="form-title">Delete recipe</div>
                    </div>
                    {recipe.image && <div className="row-input image">
                        <img src={`/recipe_images/` + recipe.image}/> 
                    </div>}
                    <div className="row-input">
                        Please confirm that you would like to delete the recipe for <span className="delete-title">{recipe.title}</span>
                    </div>
                </div>
                <div className="action-buttons">
                    <button 
                        className="confirm-delete" 
                        onClick={onConfirmDelete}
                    >
                        Confirm
                    </button>
                    <button
                        className="cancel-delete"
                        onClick={() => setFormType("EDIT")}
                    >
                        Cancel
                    </button>
                </div>
            </div>}
    </div>;
}