import React, { useCallback, useContext, useState } from "react";
import './cookbookAddForm.scss';
import { Toggle } from "./Toggle";
import { getCsrfToken } from "../utils";
import { UserContext } from "../../appContext";
import { useNavigate } from "react-router-dom";
import { TCookbook } from "../../types/cookbook";

type TCookbookAddFormProps = {
    isVisible: boolean;
    setIsVisible: (v: boolean) => void;
    refreshCookbooks: () => void;
}

export const CookbookAddForm = ({isVisible, setIsVisible, refreshCookbooks}: TCookbookAddFormProps) => {
    const [title, setTitle] = useState("");
    const [isDefault, setIsDefault] = useState(false);

    const {refreshCurrentUser} = useContext(UserContext);

    const onExit = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsVisible(false);
        setTitle("");
        setIsDefault(false);
    }, [setIsVisible, setTitle, setIsDefault]);

    const navigate = useNavigate();

    const onCreateNotebook = useCallback(() => {
        // console.log({
        //     title,
        //     setAsDefault: isDefault,
        // });

        const csrfToken = getCsrfToken();
        fetch('/api/cookbooks/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                title,
                setAsDefault: isDefault
            })
        }).then(response => response.json())
        .then(data => {
            refreshCookbooks();
            if (isDefault) {
                // Refresh current user if new default is set
                refreshCurrentUser();
            }

            setIsVisible(false);
            setTitle("");
            setIsDefault(false);

            const newCookbook = data as TCookbook;
            navigate(`/cookbook/${newCookbook.id}?confetti=1`);
        });
    },[title, isDefault, refreshCookbooks, setIsVisible, setTitle, setIsDefault, navigate]);

    return <div className={"add-cookbook-container" + (isVisible ? " show" : "")}
        onClick={onExit}>
        {/* NOTE: isVisible is added to condition trivially to trick auto focus into working */}
        {isVisible && <div className="add-cookbook-content" onClick={e => e.stopPropagation()}>
            <div className="add-cookbook-form">
                <div className="form-title-container">
                    <div className="form-title">
                        New cookbook
                    </div>
                </div>
                <div className="form-subtitle">
                    Let's create a new cookbook for storing and sharing recipes!
                </div>
                <div className="row-input">
                    <div>
                        <label className={title ? "filled" : ""}>Title</label>
                    </div>
                    <div>
                        <input
                            type="text"
                            value={title}
                            placeholder="Cookbook title"
                            onChange={(e) => setTitle(e.target.value)}
                            className={title ? " filled" : ""}
                            autoFocus={true}
                        />
                    </div>
                </div>
                <div className="row-input">
                    <Toggle 
                        onToggle={() => setIsDefault((prev) => !prev)} 
                        explanation={"Set as default cookbook"}
                        className="cookbook"
                    />
                </div>
            </div>
            <div className="action-buttons">
                    <button 
                        className="create-cookbook" 
                        disabled={!title}
                        onClick={onCreateNotebook}
                    >
                        Create cookbook
                    </button>
                </div>
        </div>}
    </div>
};