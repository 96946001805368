import React, { useState } from "react";
import logo from "../assets/sushi_wee.png";
import { TRecipe } from "../types/recipe";
import "./titleBar.scss";
import { minutesToPrepTime } from "./utils";


type TTitleBarProps = {
    setIsVerticalSplit: (isVerticalSplit: boolean) => void;
    isVerticalSplit: boolean,
    setIsCookingMode: (isCookingMode: boolean) => void,
    isCookingMode: boolean,
    fetchAndSetAllRecipes: () => void,
    selectRecipe: (recipe?: TRecipe) => void,
    updateRecipesFilter: (filter: string, opts?: { ingredientsLimit: number; timeLimit: number}) => void;
    maxIngredientsCount: number;
    maxCookingTime: number;
};

export const TitleBar = ({
    setIsVerticalSplit,
    isVerticalSplit,
    setIsCookingMode,
    isCookingMode,
    fetchAndSetAllRecipes,
    selectRecipe,
    updateRecipesFilter,
    maxIngredientsCount,
    maxCookingTime
}: TTitleBarProps) => {
    const [url, setUrl] = useState("");

    const [filter, setFilter] = useState("");

    const onClickScrape = () => {
      if (!url) {
        return;
      }
      const csrfToken = document.cookie.split(";").find(token => token.startsWith('csrftoken'))?.split('=')[1];
      // console.log(csrfToken);
      fetch('/api/scrape', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken ?? "",
        },
        body: JSON.stringify({url})
      }).then((response) => response.json()).then(data => {
      setUrl("");
      fetchAndSetAllRecipes();
      selectRecipe(data);
      setShowScrape(false);
      });
    }

    const toggleCookingMode = () => {
        setIsCookingMode(!isCookingMode);
    }

    const toggleVerticalSplit = () => {
        setIsVerticalSplit(!isVerticalSplit);
    }

    const resetRecipeSelection = () => {
      selectRecipe(undefined);
    }

    const [showScrape, setShowScrape] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const cancelSearch = () => {
      setFilter('');
      updateRecipesFilter('', undefined);
      setShowSearch(false);
    }

    const [ingredientsLimit, setIngredientsLimit] = useState(15);
    const [timeLimit, setTimelimit] = useState(90);

    return <div>
        <div className="cooking-mode-buttons">
          <button onClick={toggleVerticalSplit}>
            Show {isVerticalSplit ? "Horizontal" : "Vertical"} Split
          </button>
          <button onClick={toggleCookingMode}>
            {isCookingMode ? "Exit" : "Enter"} Cooking Mode
          </button>
        </div>
        <div className={(isCookingMode ? "is-cooking " : " ") + "top-nav"}>
          <div className="app-title" onClick={resetRecipeSelection}>
            <img src={logo} height="32px"/>
            <h1>
              Recipe Tracker
            </h1>
          </div>
          {!showScrape && !showSearch && <div className="title-bar-actions">
            <button className="title-bar-button"
              onClick={() => setShowScrape(true)}
            >
              Add Recipe
            </button>
            <button className="title-bar-button"
              onClick={() => setShowSearch(true)}
            >
              Search Recipes
            </button>
          </div>}
          {showScrape && <div>
            <input type="text" className={"title-bar-input"} value={url} onChange={(e) => {setUrl(e.target.value)}}/>
            <button className="title-bar-button"
              onClick={onClickScrape}
            >
              Scrape Recipe
            </button>
            <button className="title-bar-button"
              onClick={() => setShowScrape(false)}
            >
              Cancel
            </button>
          </div>}
          {showSearch && <div>
            <input type="text" className={"title-bar-input search"} value={filter} onChange={(e) => {setFilter(e.target.value)}}/>
            <input type="range" className={"title-bar-slider"}
              min={1} 
              max={maxIngredientsCount} 
              value={ingredientsLimit} 
              onChange={e => setIngredientsLimit(parseInt(e.target.value))}/> 
              ≤ {ingredientsLimit} ingredients
            <input type="range" className={"title-bar-slider"}
              min={1} 
              max={maxCookingTime} 
              value={timeLimit} 
              onChange={e => setTimelimit(Math.round(parseInt(e.target.value) / 15) * 15)}/> 
              ≤ {minutesToPrepTime(timeLimit)}
            <button className="title-bar-button"
              onClick={() => updateRecipesFilter(filter, {ingredientsLimit, timeLimit})}
            >
              Search Recipes
            </button>
            <button className="title-bar-button"
              onClick={cancelSearch}
            >
              Cancel
            </button>
          </div>}
        </div>
      </div>;
}