import React, { useEffect, useMemo, useState } from "react";
import './recipePhotoGallery.scss';
import xmarkSvg from '../../assets/xmark.svg';
import addImageSvg from '../../assets/addImage.svg';
import { useSwipeable } from "react-swipeable";
import classNames from "classnames";

type TGalleryImage = { src: string };

type TRecipePhotoGalleryProps = {
    recipeId?: number | string ;
    title: string;
    gallery: TGalleryImage[];
    isVisible: false | "FROM_RECIPE" | "FROM_UPLOAD";
    setIsVisible: (v: false | "FROM_RECIPE" | "FROM_UPLOAD") => void;
    setShowPhotoUpload: (v: false | "FROM_RECIPE" | "FROM_GALLERY") => void;
    loggedIn: boolean;
}

const GALLERY_COLUMNS = 3;

export const RecipePhotoGallery = ({
    recipeId, 
    title,
    gallery, 
    isVisible, 
    setIsVisible,
    setShowPhotoUpload,
    loggedIn }: TRecipePhotoGalleryProps
    ) => {

    useEffect(() => {
        if (isVisible) {
            window.scrollTo(0, 0);
        }
    }, [isVisible]);

    const galleryColumns: Array<TGalleryImage[]> = useMemo(() => {
        // note: each row actually ends up representing a column visually
        // generally, we want the lower indices from the full gallery image
        // array to display higher up on the screen
        const result: Array<Array<TGalleryImage>> = [];
        for (let i = 0; i < GALLERY_COLUMNS; i++) {
            result.push(new Array<TGalleryImage>());
        }

        gallery.forEach((image, idx) => {
            const column = idx % GALLERY_COLUMNS;
            result[column].push(image)
        });

        return result;
    }, [gallery]);

    const swipeHandlers = useSwipeable({
        onSwipedRight: (e) => {
            if (photoZoom) {
                setPhotoZoom(null);
                return;
            }
            setIsVisible(false);
        },
    });

    const [photoZoom, setPhotoZoom] = useState<string | null>(null);

    return <div 
        className={`recipe-photo-gallery-container${isVisible ? " show" : ""}${isVisible === "FROM_RECIPE" ? " from-recipe" : ""}`} 
        onClick={() => {
            if (photoZoom) {
                setPhotoZoom(null);
                return;
            }
            setIsVisible(false);
        }} 
        {...swipeHandlers} >
        <div className="recipe-photo-gallery-close" onClick={() => {
            if (photoZoom) {
                setPhotoZoom(null);
                return;
            }
            setIsVisible(false);
        }} >
            <img src={xmarkSvg} alt="close" />
        </div>
        <div className={classNames("photo-zoom", photoZoom ? "show" : "")}>
            {photoZoom && <img src={'/recipe_images/' + photoZoom} alt="photo" />}
        </div>
        {!photoZoom && <>
            <div className="recipe-photo-upload-open" onClick={(e) => {
                e.stopPropagation();
                setShowPhotoUpload("FROM_GALLERY");
                if (loggedIn) {
                    setIsVisible(false);
                }
            }} >
                <img src={addImageSvg} alt="open upload" />
            </div>
            <div className={`recipe-photo-gallery-title`}>
                {title} Photos
            </div>
            <div className="recipe-photo-gallery">
            {
                galleryColumns.map((g, colIdx) => <div
                    style={
                        {
                            width: `calc(${Math.floor(100 / GALLERY_COLUMNS)}% - 
                                ${Math.floor((GALLERY_COLUMNS - 1) * 4 / GALLERY_COLUMNS)}px)`,
                        }
                    }
                    key={`${recipeId ?? 0}_col_${colIdx}`}
                >
                    {
                        g.map(image =>
                            <img 
                                src={'/recipe_images/' + image.src} 
                                key={image.src}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPhotoZoom(image.src);
                                }}
                            />)
                    }
                </div>
                )}
            </div>
        </>}
        <div className="recipe-photo-gallery-modal"/>
    </div>
}