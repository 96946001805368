import React, {useState, useEffect} from "react";
import "./instructions.scss"

type TInstructionsProps = {
    text: string;
    recipeId: number;
}

export const Instructions = ({text, recipeId}: TInstructionsProps) => {
    const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);

    useEffect(() => {
        setCurrentStep(undefined);
    }, [recipeId])

    const updateCurrentStep = (idx: number) => () => {
        if (currentStep === idx) {
            setCurrentStep(undefined);
        } else {
            setCurrentStep(idx);
        }
    };

    return  <div className="instructions-container">
        <div><h2>Instructions</h2></div>
        <div>
            <div className={"instructions-list"}>
            {text.split('\n').map((step, idx) => 
                <div onClick={updateCurrentStep(idx)} className={"instruction-step " + 
                    (currentStep === undefined ? "no-selected-step" : 
                        currentStep === idx ? "selected-step" : "unselected-step")} 
                key={recipeId + "." + idx + "." + step}>
                    <div className={"step-index"}>
                        {`${idx + 1}.`}
                    </div>
                    <div>
                        {step}
                    </div>
                </div>)}
            </div>
        </div>
    </div>;
}