import React, { useCallback, useContext, useMemo } from "react";
import placeholder from "../../assets/cookingPot.svg";
import clock from "../../assets/clock.svg";
import carrot from "../../assets/carrot.svg";
import bookmark from "../../assets/bookmark.svg";
import bookmarkSolid from "../../assets/bookmarkSolid.svg";
import "./recipePanel.scss";
import { TRecipeAbridged } from "../../types/cookbook";
import { minutesToPrepTime } from "../utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { PlannerContext } from "../../appContext";

type TRecipePanelProps = {
    recipe: TRecipeAbridged;
    searchPage?: boolean;
    className?: string;
    hideText?: boolean;
    hidePlanner?: boolean;
}

type TRecipePanelsProps = {
    recipes: Array<TRecipeAbridged>;
}

export const RecipePanel = ({ recipe, searchPage, className, hideText, hidePlanner }: TRecipePanelProps) => {
    const { addToPlanner, currentPlanner, removeFromPlanner } = useContext(PlannerContext);
    const inPlanner = useMemo(() => {
        return recipe.id in currentPlanner.groceries;
    }, [recipe.id, currentPlanner.groceries])

    const toggleBookmark = useCallback(() => {
        if (!inPlanner) {
            addToPlanner(recipe.id);
            return;
        }
        removeFromPlanner(recipe.id)
    }, [addToPlanner, removeFromPlanner, recipe.id, inPlanner]);

    const innerContent = <>
            <div className={classNames("recipe-panel-image", className)}>
            {!hideText && <><div className={classNames("recipe-panel-title", "data", className) }>
                {recipe.total_time && <div className="data-time">
                    <img src={clock} alt="cooking time" />
                    {minutesToPrepTime(recipe.total_time)}
                </div>}
                {searchPage &&<div className="data-ingredients">
                    <img src={carrot} alt="ingredient count" />
                    {recipe.ingredients.length} ingredients
                </div>}
            </div>
            <div className={classNames("recipe-panel-title", className)}>
                {recipe.title}
            </div></>}
        <div className={classNames("recipe-panel-vignette", className) } />
            {
                recipe.image ?
                    <img src={`/recipe_images/` + recipe.image} /> :
                    <img className="placeholder-image" src={placeholder} />
            }
        </div>
    </>;

    return <div className={classNames("recipe-panel", className)}>
        {!hidePlanner && <div className="add-to-planner" onClick={toggleBookmark}>
            <div>
                <img src={inPlanner ? bookmarkSolid : bookmark } 
                    alt={inPlanner ? "already in planner" : "add to planner"} />
            </div>
        </div>}
        {!hideText && <Link to={`/recipe/${recipe.id}`} key={recipe.id} >
                {innerContent}
            </Link>
        }
        {hideText && innerContent}
    </div>;
};

export const RecipePanels = ({ recipes }: TRecipePanelsProps) => {
    return <div className="recipe-panels-container">
        {recipes.map(recipe => 
                <RecipePanel recipe={recipe} key={recipe.id}/>
        )}
    </div>
};