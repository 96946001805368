import React, { useMemo } from "react";
import './goSpinner.scss';

type TGoSpinnerProps = {
    color?: string;
    className?: string;
}

export const GoSpinner = ({color, className}: TGoSpinnerProps) => {
    const background = color ?? "white";

    const innerDivs = useMemo(() => {
        const result = [];
        for (let i = 0; i < 12; i++) {
            result.push(i);
        }

        return result;
    }, []);

    return <div className={"lds-default" + (className ? " " + className : "")}>
        {
            innerDivs.map(v =>
                <div 
                    key={"go-spinner-" + v} style={{
                        background,
                    }}>
                </div>
            )
        }
    </div>;
};