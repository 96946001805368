import React, { useCallback, useContext, useState } from "react";
import { SnackBarContext, UserContext } from "../../appContext";
import { useNavigate } from "react-router-dom";
import './settingsPage.scss';
import { ProfilePictureSettings } from "./ProfilePictureSettings";
import { getCsrfToken } from "../utils";

type TSettingsPageProps = {
    setBeta: (v: boolean) => void;
}

export const SettingsPage = ({setBeta}: TSettingsPageProps) => {
    const userContext = useContext(UserContext);
    const { logOut, refreshCurrentUser } = userContext;
    const user = userContext.loggedIn ? userContext.user : undefined;

    const navigate = useNavigate();
    const onLogOut = useCallback(() => {
        logOut(() => navigate('/'));
    }, [logOut, navigate]);

    const activeInvites = user?.invites.filter(invite => invite.active).map(invite => invite.token) ?? [];

    const { addSnack } = useContext(SnackBarContext);
    const onCopyInvite = useCallback((token: string) => {
        const protocol = window.location.protocol.startsWith("https") ? "https://" : "http://"
        const inviteLink = `${protocol}${window.location.host}/invited/${token}`;
        return () => {
            navigator.clipboard.writeText(inviteLink).then(
                () => {
                    addSnack({ id: `copy-invite-${token}-success`, message: "Copied invite link to clipboard"});
                }, 
                () => {
                    addSnack({ id: `copy-invite-${token}-failure`, message: "Unable to copy invite link to clipboard"});
                }, 
            );
        };
    }, [addSnack]);

    const onCreateInvite = useCallback(() => {
        const csrfToken = getCsrfToken();

        fetch('/api/invite',
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
        }
        ).then(response => response.json())
        .then(data => {
            if (!("token" in data)) {
                console.log("Error encountered when generating invite token")
                return;
            }

            refreshCurrentUser();

            const token = data.token as string;
            onCopyInvite(token)();
        })
        .catch(_ => {
            console.log("Error encountered when generating invite token")
        })
    }, [refreshCurrentUser, onCopyInvite]);

    if (!user) {
        return <div className="settings-page-container">
            "Oooops"
        </div>;
    }

    return <>
        <div className="beta-button-container" style={{textAlign: "right"}}>
            <button onClick={() => setBeta(false)}>Exit Beta</button>
        </div>
        <div className="settings-page-container">
            <div className="settings-page">
                <div className="settings-row">
                    <div className="row-title">Profile picture</div>
                    <ProfilePictureSettings 
                        user={user}
                        refreshCurrentUser={refreshCurrentUser}
                    />
                </div>
                <div className="settings-row">
                    <div className="row-title">User info</div>
                    <div className="user-info">
                        <div className="user-info-row">
                            <div className="label">
                                Username
                            </div>
                            <div className="value">
                                {user.username}
                            </div>
                        </div>
                        <div className="user-info-row">
                            <div className="label">
                                Email
                            </div>
                            <div className="value">
                                {user.email}
                            </div>
                        </div>
                        <div className="user-info-row">
                            <div className="label">
                                First name
                            </div>
                            <div className="value">
                                {user.firstName || "--"}
                            </div>
                        </div>
                        <div className="user-info-row">
                            <div className="label">
                                Last name
                            </div>
                            <div className="value">
                                {user.lastName || "--"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="settings-row">
                    <div className="row-title">Invite friends</div>
                    {activeInvites.length > 0 && <div>
                        <div className="active-invites">
                            Active invites
                        </div>
                        {activeInvites.map(invite => 
                        <div key={invite} className="invite-row" onClick={onCopyInvite(invite)}>
                            <div className="invite-token">
                                {invite}
                            </div>
                            <div className="copy-invite">
                                Copy
                            </div>
                        </div>)}
                    </div>}
                    <button className="invite" onClick={onCreateInvite}>Create new invite</button>
                </div>
                <div className="settings-row">
                    <div className="row-title">Log out</div>
                    <button className="log-out" onClick={onLogOut}>Log out</button>
                </div>
            </div>
        </div>
    </>
}