import React from "react";
import { TRecipe } from "../types/recipe";
import "./recipeList.scss"

type TRecipeTitleProps = {
  recipe: TRecipe,
  isSelected: boolean,
  selectRecipe: (recipe?: TRecipe) => void; 
}

type TRecipeListProps = {
  recipes: Array<TRecipe>;
  isCookingMode: boolean;
  selectedRecipe?: TRecipe;
  selectRecipe: (recipe?: TRecipe) => void;
}

const RecipeTitle = ({
  recipe, 
  isSelected, 
  selectRecipe}: TRecipeTitleProps) => {
  const onClick = () => {
    if (!isSelected) {
      selectRecipe(recipe);
    }
  }

  const titleSelectionCss = `recipe-title ` + (isSelected ? `selected-recipe-title` : `unselected-recipe-title`);

  return <div onClick={onClick} className={titleSelectionCss}>
      <li key={recipe.id}>
        <span
          title={recipe.description === "N/A" ? undefined : recipe.description}
        >
          {recipe.title}
        </span>
      </li>
    </div>
}

export const RecipeList = (
  { recipes, 
    isCookingMode, 
    selectedRecipe, 
    selectRecipe}: TRecipeListProps) => {
  return <div className={(isCookingMode ? "is-cooking " : " ") + "recipe-nav"}>
      <ul className="recipe-list">
      { recipes.map(recipe => <RecipeTitle key={recipe.id} recipe={recipe} isSelected={selectedRecipe?.id === recipe.id} selectRecipe={selectRecipe} />)}
      </ul>
  </div>
}