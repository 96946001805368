import React, { useCallback, useContext, useEffect, useState } from "react";
import "./cookbooksPage.scss";
import { UserContext } from "../../appContext";
import { TCookbook } from "../../types/cookbook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import chevronLeftSvg from "../../assets/chevronLeft.svg";
import placeholderSvg from "../../assets/cookingPot.svg";
import listViewSvg from "../../assets/listView.svg";
import bookViewSvg from "../../assets/bookView.svg";
import classNames from "classnames";
import { CookbookAddForm } from "./CookbookAddForm";
import { getPublicCookbookToken } from "../utils";

const CookbookCarousel = ({cookbooks}: {cookbooks: TCookbook[]}) => {
    const [index, setIndex] = useState(0);

    const slideToRight = useCallback(() => {
        setIndex((prevIndex) => prevIndex === cookbooks.length - 1 ? prevIndex : prevIndex + 1);
    }, [setIndex, cookbooks]);

    const slideToLeft = useCallback(() => {
        setIndex((prevIndex) => prevIndex === 0 ? prevIndex : prevIndex - 1);
    }, [setIndex]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (e) => {
            console.log("swiped left");
            // setIndex((prevIndex) => prevIndex === cookbooks.length - 1 ? prevIndex : prevIndex + 1);
            slideToRight();
        },
        onSwipedRight: (e) => {
            // setIndex((prevIndex) => prevIndex === 0 ? prevIndex : prevIndex - 1);
            slideToLeft();
            console.log("swiped right")
        }
    });

    return (
        <div className="slideshow" {...swipeHandlers}>
            <div className={classNames("slide-arrow", "left", index > 0 ? undefined : "disabled")} onClick={slideToLeft}>
                <img src={chevronLeftSvg} alt="slide left" />
            </div>
            <div className={classNames("slide-arrow", "right", index < cookbooks.length - 1 ? undefined : "disabled" )} onClick={slideToRight}>
                <img src={chevronLeftSvg} alt="slide right" />
            </div>
            <div
                className="slideshowSlider"
                style={cookbooks.length > 1 ? { transform: `translate3d(${-index * 100}%, 0, 0)` } : undefined}
            >
                {cookbooks.map((cookbook, index) => {
            
                const contributorCount = cookbooks[index].viewers.filter(u => u.is_contributor).length;
                const coverImage = cookbook.image || (cookbook.recipes.length > 0 ? cookbook.recipes[0].image : undefined);
                const imgSrc = coverImage ? `/recipe_images/${coverImage}` : placeholderSvg;

                return <div className="slide" key={index + cookbook.title}>
                        <Link to={`/cookbook/${cookbook.id}`}>
                            <div className="slide">
                                <div className="slideshow-title">
                                    {cookbook.title}
                                </div>
                                <div className="slideshow-data">
                                    <div className="recipes-data">
                                        {cookbook.recipes.length} Recipe{cookbook.recipes.length === 1 ? "" : "s"}
                                    </div>
                                    <div className="contributors-data">
                                        {contributorCount} Contributor{contributorCount === 1 ? "" : "s"}
                                    </div>
                                </div>
                                <div className="slide-vignette" />
                                <img src={imgSrc} alt={cookbook.title} />
                            </div>
                        </Link>
                    </div>;
                })}
            </div>
        </div>
    )
}

const CookbookList = ({cookbooks}: {cookbooks: TCookbook[]}) => {
    const navigate = useNavigate();
    return <div className="cookbooks-list-container">
        {cookbooks.map(cookbook => {
            const contributorCount = cookbook.viewers.filter(u => u.is_contributor).length;
            const coverImage = cookbook.image || (cookbook.recipes.length > 0 ? cookbook.recipes[0].image : undefined);
            const imgSrc = coverImage ? `/recipe_images/${coverImage}` : placeholderSvg;

            return <div 
                key={`${cookbook.id}-${cookbook.title}`} 
                className="cookbook-row-container"
                onClick={() => navigate(`/cookbook/${cookbook.id}`)}
                >
                <div className="cookbook-row-image-container">
                    <div className="cookbook-panel-vignette" />
                    <img src={imgSrc} alt="cookbook cover photo" />
                </div>
                <div className="cookbook-row-details">
                    <div>
                        <div className="cookbook-title">
                            {cookbook.title}
                        </div>
                        <div className="cookbook-data">
                            {cookbook.recipes.length} Recipe{cookbook.recipes.length === 1 ? "" : "s"} | {contributorCount} Contributor{contributorCount === 1 ? "" : "s"}
                        </div>
                    </div>
                    <div className="creator">
                        Created by: {cookbook.creator.first_name || cookbook.creator.username}
                    </div>
                </div>
            </div>;
        })}
    </div>
}

export const CookbooksPage = () => {
    const userContext = useContext(UserContext);

    const [cookbooks, setCookbooks] = useState<Array<TCookbook>>([]);

    const refreshCookbooks = useCallback(() => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch('/api/cookbooks', { headers: requestHeaders })
        .then(response => response.json())
        .then(data => {
            // NOTE: Important to create a separate instance of cookbook array here
            // in order for React to know to rerender when updating state
            const newCookbooks = data as Array<TCookbook>;
            setCookbooks(newCookbooks);
            // console.log(newCookbooks);
        })
        .catch(_ => {
            console.log("Unable to fetch cookbooks");
        });
    }, [setCookbooks]);

    useEffect(() => {
        refreshCookbooks();
    }, [userContext.loggedIn]);

    const [showAddForm, setShowAddFormRaw] = useState(false);
    const setShowAddForm = useCallback((v: boolean) => {
        if (v === false) {
            setShowAddFormRaw(v);
            return;
        }

        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Only registered users can create new cookbooks."});
            return;
        }

        setShowAddFormRaw(v);
    }, [setShowAddFormRaw, userContext]);

    const [view, setView] = useState<"BOOK" | "LIST">("BOOK");

    const currentLocation = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const queryView = queryParams.get('view');

        if (!queryView) {
            return;
        }

        setView(queryView.toUpperCase() as "BOOK" | "LIST");
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/cookbooks?view=${view}`, {replace: true, preventScrollReset: true})
    }, [navigate, view]);

    return <div className="cookbooks-page-container" key="cookbooks-page">
        <CookbookAddForm 
            isVisible={showAddForm}
            setIsVisible={setShowAddForm}
            refreshCookbooks={refreshCookbooks}
        />
        <div className="cookbooks-page">
            <div className="title-bar-container">
                <div className="title-bar-left">
                    <div className="title-bar">
                        <div>
                            Cookbooks
                        </div>
                        <div className="title-bar-new" onClick={() => setShowAddForm(true)}>
                            + NEW
                        </div>
                    </div>
                    <div className="subtitle-bar">
                        <div>
                            Which cookbook takes your fancy?
                        </div>
                        <div className="sort-container" onClick={() => setView(view === "BOOK" ? "LIST" : "BOOK")}>
                            <img src={view === "BOOK" ? listViewSvg : bookViewSvg } alt={view === "BOOK" ? "show list view" : "show slide view"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames("cookbooks-content", view === "LIST" ? "no-padding" : "")}>
                {view === "BOOK" ? 
                    <CookbookCarousel cookbooks={cookbooks}/> : 
                    <CookbookList cookbooks={cookbooks}/>
                }
            </div>
        </div>
    </div>
}