import React, {useState, useEffect, useRef, useContext, useCallback} from "react";
import "./instructions.scss"
import { PlannerContext } from "../../appContext";

type TInstructionsProps = {
    text: string;
    recipeId: number;
    currentStepRef: React.MutableRefObject<HTMLDivElement | null>;
    isActivelyCooking: boolean;
}

export const Instructions = ({text, recipeId, currentStepRef, isActivelyCooking}: TInstructionsProps) => {
    const { currentPlanner, setCookingStep } = useContext(PlannerContext);
    const [currentStep, setCurrentStepWrap] = 
        useState<number | null>(isActivelyCooking ? 
                                        currentPlanner.groceries[recipeId].cookingStep : 
                                        null);
    
    const setCurrentStep = useCallback((s: number | null) => {
        setCurrentStepWrap(s);
        if (isActivelyCooking && currentPlanner.stage === "COOKING") {
            setCookingStep({recipeId, step: s});
        }
    }, [setCurrentStepWrap, setCookingStep, recipeId, isActivelyCooking, currentPlanner.stage]);

    useEffect(() => {
        setCurrentStep(isActivelyCooking ? currentPlanner.groceries[recipeId].cookingStep : null);
    }, [recipeId, isActivelyCooking])

    const updateCurrentStep = (idx: number) => () => {
        if (currentStep === idx) {
            setCurrentStep(null);
        } else {
            setCurrentStep(idx);
        }
    };

    return  <div className="instructions-container">
        <div className={"instructions-list"}>
        {text.split('\n').map((step, idx) => 
            <div onClick={updateCurrentStep(idx)} className={"instruction-step " + 
                (currentStep === null ? "no-selected-step" : 
                    currentStep === idx ? "selected-step" : "unselected-step")} 
            key={recipeId + "." + idx + "." + step} ref={currentStep === idx ? currentStepRef : null}>
                <div className={"step-index"}>
                    {`${idx + 1}.`}
                </div>
                <div>
                    {step}
                </div>
            </div>)}
        </div>
    </div>;
}