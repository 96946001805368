import React, { useState } from "react";
import './ingredients.scss';

type TIngredientCheckboxProps = {
  ingredient: string;
}

type TIngredientsProps = {
  recipeId: number;
  ingredients: Array<string>
}

const IngredientCheckbox = ({ingredient}: TIngredientCheckboxProps) => {
  const [checked, setChecked] = useState(false);
  const onClick = () => {
    setChecked(!checked);
  };
  const onChange = () => {
    return;
  }

  return <li key={ingredient} className={"ingredient-row"}>
    <label className={"ingredient-row"}>
      <input className={"ingredient-row"} key={ingredient} onClick={onClick} onChange={onChange} type="checkbox" value={ingredient} checked={checked}/>
      <span className={checked ? 'checked' : undefined}>{ingredient}</span>
    </label>
  </li>
}

export const Ingredients = ({recipeId, ingredients}: TIngredientsProps) => {
    return <div className="ingredients-container">
        <div><h2>Ingredients</h2></div>
        <div>
            <ul className={"ingredients-list"}>
            {ingredients.map((ingredient, idx) => <IngredientCheckbox key={recipeId + ': ' + idx} ingredient={ingredient}/>)}
            </ul>
        </div>
    </div>;
}