import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import plusSignSvg from '../../assets/plusSign.svg';
import heartPlusSvg from '../../assets/heartPlusLight.svg';
import './fab.scss';
import { getCsrfToken, isValidUrl } from "../utils";
import { useNavigate } from "react-router-dom";
import { TRecipe } from "../../types/recipe";
import { GoSpinner } from "./GoSpinner";
import { SnackBarContext, UserContext } from "../../appContext";
import { TCookbook } from "../../types/cookbook";
import classNames from "classnames";
import { Toggle } from "./Toggle";

type TAddRecipeFabProps = {
    cookbookPage?: {
        cookbookId: number;
        title: string;
        open: boolean;
        setOpen: (v: boolean) => void;
    }
    className?: string;
}

export const AddRecipeFab = ({cookbookPage, className}: TAddRecipeFabProps) => {
    const [expandFab, setExpandFab] = useState(true);

    const [openWrap, setOpenWrap] = useState(false);
    const [open, setOpen] = useMemo(() => {
        if (!cookbookPage) {
            return [openWrap, setOpenWrap];
        }

        return [cookbookPage.open, cookbookPage.setOpen];
    }, [cookbookPage, openWrap, setOpenWrap]);

    const userContext = useContext(UserContext);

    const [cookbooks, setCookbooks] = useState<TCookbook[]>([]);

    const refreshCookbooks = useCallback(() => {
        fetch('/api/cookbooks')
        .then(response => response.json())
        .then(data => {
            setCookbooks(data as TCookbook[]);
        })
        .catch(_ => {
            console.log("Unable to fetch cookbooks");
        });
    }, [setCookbooks]);

    useEffect(() => {
        if (open) {
            refreshCookbooks();
        }
    }, [userContext.loggedIn, open]);

    useEffect(() => {
        if (!window) {
            return;
        }

        const scrollListener = () => {
            if (window.scrollY === 0) {
                // console.log("Scrolled to top");
                setExpandFab(true);
                return;
            }

            if (expandFab) {
                setExpandFab(false);
            }
        };

        window.addEventListener("scroll", scrollListener);

        return () => window.removeEventListener("scroll", scrollListener);
    }, [!window]);

    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    const [selectedCookbook, selectCookbook] = useState<number>(0);
    const [updateDefaultCookbook, setUpdateDefaultCookbook] = useState(false);

    const cookbookTitle = cookbookPage?.title ?? cookbooks.find(c => c.id === selectedCookbook)?.title ?? "";
    const {addSnack} = useContext(SnackBarContext);

    const onAddRecipe = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        const source = inputValue;
        if (loading || !source) {
            return;
        }

        if (!isValidUrl(source)) {
            setErrorMessage("Invalid url input");
            return;
        }
        
        setLoading(true);
        setErrorMessage(null);
        
        const csrfToken = getCsrfToken();
        fetch('/api/scrape', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({ 
                url: source, 
                cookbookId: cookbookPage ? 
                    cookbookPage.cookbookId : 
                    selectedCookbook,
                updateDefaultCookbook,
            })
        }).then((response) => response.json()).then(data => {
            if (!("id" in data)) {
                setErrorMessage("Unable to add recipe. Try another!");
                setLoading(false);
                // setInputValue("");
                console.log("Unable to scrape recipe");

                return;
            }
            
            const recipe = data as TRecipe;
            setLoading(false);
            navigate(`/recipe/${recipe.id}?confetti=1`);

            if (updateDefaultCookbook) {
                userContext.refreshCurrentUser();
                addSnack({
                    id: `update-default-cookbook-${cookbookPage?.cookbookId ?? selectedCookbook}`,
                    message: `Default cookbook has been changed to '${cookbookTitle}'.`
                });
            }
        }).catch(() => {
            // setInputValue("");
            setErrorMessage("Unable to add recipe. Try another!");
            setLoading(false);
            console.log("Unable to scrape recipe");
        });
    }, [loading, 
        setLoading, 
        navigate, 
        inputValue, 
        setInputValue, 
        selectedCookbook, 
        cookbookPage, 
        updateDefaultCookbook,
        userContext.refreshCurrentUser,
        addSnack,
        cookbookTitle
    ]);

    const onClickOut = useCallback(() => {
        setInputValue("");
        setErrorMessage(null);
        setOpen(false);
    }, [setOpen, setInputValue]);

    const onChangeInputValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        if (errorMessage) {
            setErrorMessage(null);
        }
    }, [setInputValue, errorMessage, setErrorMessage]);

    const onOpenAttempt = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Only registered users can add new recipes."});
            return;
        }

        selectCookbook(userContext.user.defaultCookbook ?? 0);
        setOpen(true);
    }, [setOpen, userContext.loggedIn, userContext.setShowModal]);

    useEffect(() => {
        setUpdateDefaultCookbook(false);
    }, [selectedCookbook])

    return <>
        <div 
        className={classNames("recipe-fab-container", 
            open ? "opened" : undefined)} 
        onClick={onClickOut}>
        {!cookbookPage && <div className={"recipe-fab" + (expandFab ? "" : " collapsed") + (open ? "" : " show")} onClick={onOpenAttempt}>
            <div className="icon-container">
                <img src={plusSignSvg} alt="add recipe"/>
            </div>
            {expandFab && <div>ADD RECIPE</div>}
        </div>}
        { <div 
            className={classNames("recipe-fab-opened", 
                open ? "show": undefined,
                className,
                errorMessage ? " error-message" : undefined,
                userContext.loggedIn && userContext.user.defaultCookbook !== selectedCookbook ? "default-toggle" : "")} 
            onClick={(e) => e.stopPropagation()}>
                <div className={"recipe-fab-content"}>
                    {cookbookPage && <div className="cookbook-title">
                        Add new cookbook recipe
                    </div>}
                    <div className={classNames("add-recipe-input", className)}>
                        {open && // trick autofocus
                        <div className="input-container">
                            <label className={inputValue ? "filled" : ""}>Recipe url</label>
                            <input
                                type="text"
                                placeholder="Recipe url"
                                autoFocus={true}
                                value={inputValue}
                                onChange={onChangeInputValue}
                                disabled={loading}
                                className={inputValue ? "filled" : ""}
                            />
                        </div>
                        }
                        <div className={errorMessage ? "error-message show" : "error-message"}>
                            {errorMessage}
                        </div>
                    </div>
                    {userContext.loggedIn && !cookbookPage && <div className="add-recipe-input select">
                        <select value={selectedCookbook}
                            onChange={e => selectCookbook(parseInt(e.target.value ?? "0"))}>
                            <option key="label" value={0} disabled={true} >Select cookbook</option>
                            {cookbooks.filter(c => !!c.viewers.find(v => v.is_contributor && v.user.username === userContext.user.username)).map(c => 
                                <option key={`${c.title}`} value={c.id} >{c.title}{userContext.user.defaultCookbook === c.id ? " (default)" : ""}</option>
                            )}
                        </select>
                    </div>}
                    {userContext.loggedIn && <div className={classNames("add-recipe-input", "toggle",selectedCookbook !== userContext.user.defaultCookbook ? "show" : "")}>
                        <Toggle checked={updateDefaultCookbook} className={"add-recipe-toggle"} onToggle={() => setUpdateDefaultCookbook(!updateDefaultCookbook)} explanation={"Set selected cookbook as default"} />
                    </div>}
                    <div className={classNames("add-recipe-button", className)} onClick={onAddRecipe}>
                        <div>
                        {loading ? <GoSpinner color="#f6f7fb" className="add-recipe-loading"/> : <img src={heartPlusSvg} alt="add new recipe" /> }
                        </div>
                        <div>
                            Add new recipe
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    </>;
}