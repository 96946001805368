import React from "react";
import './cookbookCover.scss';
import placeholder from '../../assets/cookingPot.svg';
import addImageSvg from '../../assets/addImage.svg';
import viewGallerySvg from '../../assets/viewGallery.svg';
import leftArrowSvg from '../../assets/leftArrow.svg';
import { useNavigate } from "react-router-dom";
import { TCookbook } from "../../types/cookbook";
import placeholderSvg from "../../assets/cookingPot.svg";

type TCookbookCoverProps = {
    cookbook: TCookbook;
};

export const CookbookCover = ({ cookbook } : TCookbookCoverProps) => {
    const navigate = useNavigate();
    const coverImage = cookbook.image || (cookbook.recipes.length > 0 ? cookbook.recipes[0].image : undefined);
    const imgSrc = coverImage ? `/recipe_images/${coverImage}` : placeholderSvg;
    
    return <>
        <div className="cookbook-top-nav">
            <div className="left" onClick={() => {
                navigate(-1); 
            }}>
                <div className="navigate-back action-icon" >
                    <img src={leftArrowSvg} alt="navigate back" />
                </div>
            </div>
            <div className="right">
                {/* {gallery.length > 1 && <div className="view-gallery action-icon" onClick={() => setShowPhotoGallery("FROM_RECIPE")}>
                    <img src={viewGallerySvg} alt="dropdown menu" />
                </div>}
                <div className="add-image action-icon" onClick={() => setShowPhotoUpload("FROM_RECIPE")}>
                    <img src={addImageSvg} alt="dropdown menu" />
                </div> */}
            </div>
        </div>
        <div className="cookbook-page-cover-container">
            <div className="cookbook-cover-image-shadow" />
            <img src={imgSrc} alt={`Cover photo of ${cookbook.title}`} />
        </div>
    </>
}