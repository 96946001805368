import React, { MouseEvent, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TRecipe } from "../../types/recipe";
import './recipeTitle.scss';
import { minutesToPrepTime } from "../utils";
import clockSvg from "../../assets/clock.svg";
import carrotSvg from "../../assets/carrot.svg";
import linkOutSvg from "../../assets/linkOut.svg";
import pencilSvg from "../../assets/pencil.svg";
import cookingSvg from "../../assets/chefHat.svg";
import { useSwipeable } from "react-swipeable";
import { WakeLockToggle } from "./WakeLockToggle";
import { UserContext } from "../../appContext";

type TRecipeTitleProps = {
    recipe: TRecipe;
    recipeContainerRef: MutableRefObject<HTMLDivElement | null>;
    isActivelyCooking: boolean;
    isCooking: boolean;
    setIsCooking: (isCooking: boolean) => void;
    setShowRecipeEdit: (showRecipeEdit: boolean) => void;
}

export const RecipeTitle = ({ 
    recipe, 
    recipeContainerRef, 
    isActivelyCooking,
    isCooking, 
    setIsCooking,
    setShowRecipeEdit } : TRecipeTitleProps) => {
    const [expandDescription, setExpandDescription] = useState(false);

    const description = useMemo<{text: string; isExpanded: boolean}>(() => {
        if (expandDescription || 
            recipe.description.split(' ').length <= 40) {
            return { 
                text: recipe.description, 
                isExpanded: true
            };
        }

        // Limit collapsed description to first 20 words...
        const shortDescription = recipe.description.split(' ').slice(0, 20).join(' ');
        return {
            text: shortDescription,
            isExpanded: false
        };

    }, [recipe.description, expandDescription]);

    const categoryContainerRef = useRef<HTMLElement | null>(null);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (e) => {
            // console.log("User swiped left");
            if (categoryContainerRef.current) {
                categoryContainerRef.current.scrollLeft += 250;
            }
        },
        onSwipedRight: (e) => {
            // console.log("User swiped right");
            if (categoryContainerRef.current) {
                categoryContainerRef.current.scrollLeft -= 250;
            }
        }
    });

    const categoryContainerRefPassthrough = (el: HTMLElement | null) => {
        swipeHandlers.ref(el);

        categoryContainerRef.current = el;
    }

    const onClickIsCooking = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (isActivelyCooking) {
            return;
        }

        const newState = !isCooking;
        setIsCooking(newState);

        if (!newState && recipeContainerRef.current) {            
            recipeContainerRef.current.scrollIntoView({ block: "start", inline: "start", behavior: "smooth" })
        }
    }, [isCooking, setIsCooking]);

    const userContext = useContext(UserContext);

    const userCanEditRecipe: boolean = useMemo(() => {
        if (!userContext.loggedIn || !recipe) {
            return false;
        }

        return !!recipe.cookbook.viewers
            .find(v => v.is_contributor && 
                v.user.username === userContext.user.username);
    }, [userContext, recipe]);

    return <div className="recipe-title-container" >
        <div className="title-line" >
            <div className="title-text-container">
                <div className="title-text">
                    {recipe.title}
                </div>
            </div>
            {!isActivelyCooking && <div className={"cooking-mode" + (isCooking ? " focus" : "")} onClick={onClickIsCooking}>
                {!isCooking && <img src={cookingSvg} alt={"cooking mode"} className="icon"/>}
                {
                    recipe.image &&
                    <img src={`/recipe_images/${recipe.image}`} alt={recipe.title} />
                }
            </div>}
        </div>
        <div className="title-data">
            {recipe.total_time && 
                <div className="cooking-time data">
                    <div>
                        <img src={clockSvg} alt="time" />
                    </div>
                    <div>
                        {minutesToPrepTime(recipe.total_time)}
                    </div>
                </div>
            }
            <div className="ingredient-count data">
                <div>
                    <img src={carrotSvg} alt="ingredients count"/>
                </div>
                <div>
                    {recipe.ingredients.length} ings
                </div>
            </div>
            {recipe.source && <a href={recipe.source} target="_blank">
                <div className="link-out data">
                        <div>
                            <img src={linkOutSvg} alt="source link" />
                        </div>
                        <div>
                            Source
                        </div>
                </div>
            </a>}
            {(userCanEditRecipe || !userContext.loggedIn) && <div className="edit data" onClick={() => setShowRecipeEdit(true)}>
                <div>
                    <img src={pencilSvg} alt="edit recipe" />
                </div>
                <div>
                    Edit
                </div>
            </div>}
        </div>
        {recipe.tags.length > 0 && !isCooking && <div className="tags-container" {...swipeHandlers} ref={categoryContainerRefPassthrough}>
            {(recipe.tags).map(tag => {
                const t = tag.name;
                return <div className="category-tag" key={t}
                >
                    {t.charAt(0).toUpperCase() + t.slice(1).toLowerCase()}
                </div>}
            )}
        </div> }
        {recipe.description && recipe.description !== "N/A" && !isCooking && <div className="title-description-container">
            <div className="heading">Description</div>
            <div className="title-description" onClick={() => setExpandDescription(!expandDescription)}>
                {description.text}{
                    expandDescription || description.isExpanded ? undefined :
                        <>
                            <span>... </span>
                            <span className="view-more">View More</span>
                        </>
                }
            </div>
        </div>}
        <WakeLockToggle />
    </div>
}