import { TCookbook, TRecipeAbridged } from "./cookbook";

export const recipeSortOrderOptions = [
    {value: "NEW_TO_OLD_DATE", label: "Sort by most recently added", display: "NEWEST RECIPES"},
    {value: "OLD_TO_NEW_DATE", label: "Sort by least recently added", display: "OLDEST RECIPES"},
    {value: "ALPHABETICAL", label: "Sort by alphabetical order", display: "ALPHABETICAL"},
    {value: "LOW_TO_HIGH_ING", label: "Sort by fewest ingredients", display: "FEWEST INGREDIENTS"},
    {value: "LOW_TO_HIGH_TIME", label: "Sort by lowest cooking time", display: "SHORTEST TIME"},
] as const;

export type TRecipeSortOrder = typeof recipeSortOrderOptions[number]["value"];

export type TRecipe = {
    id: number;
    title: string;
    description: string;
    ingredients: Array<string>;
    instructions: string;
    image?: string;
    source?: string;
    total_time?: number;
    tags: Array<TTag>;
    cookbook: TCookbook;
    uploader: {
        first_name?: string;
        username: string;
        setting: {
            image_src?: string;
        }
    };
    created_at: string;
};

export type TTag = {
    id: number;
    name: string;
};

export type TRecipeFilter = {
    kind: 'TAG' | 'TITLE' | 'INGREDIENT';
    query: string;
} | {
    kind: 'INGREDIENTS_COUNT' | 'TIME';
    query: number;
};

const sortedRecipesByField = 
    (recipes: TRecipeAbridged[], field: Exclude<keyof TRecipeAbridged, "uploader" | "tags">, willReverse = false): TRecipeAbridged[] => {
    const compareValues = (a: string | number, b: string | number) => {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    // Use rest operator to not mutate original array
    return [...recipes].sort((rA, rB) => {
        if (field === "ingredients") {
            const compare = compareValues(rA.ingredients.length, rB.ingredients.length);
            return compare === 0 ? 
                compareValues(rA.title, rB.title) * (willReverse ? -1 : 1) :
                compare;
        }

        const a = rA[field];
        const b = rB[field];

        if (!a && !b) {
            return compareValues(rA.title, rB.title);
        }

        if (!a || !b) {
            return !a ? 1 : -1;
        }

        const compare = compareValues(a, b);
        return compare === 0 ? 
            compareValues(rA.title, rB.title) * (willReverse ? -1 : 1) :
            compare;
    })
}

export const sortedRecipes = (recipes: TRecipeAbridged[], sortOrder: TRecipeSortOrder): TRecipeAbridged[] => {
    switch(sortOrder) {
        case 'NEW_TO_OLD_DATE':
            return sortedRecipesByField(recipes, "created_at", true).reverse();
        case 'OLD_TO_NEW_DATE':
            return sortedRecipesByField(recipes, "created_at");
        case 'LOW_TO_HIGH_ING':
            return sortedRecipesByField(recipes, "ingredients");
        case 'LOW_TO_HIGH_TIME':
            return sortedRecipesByField(recipes, "total_time");
        case 'ALPHABETICAL':
            return sortedRecipesByField(recipes, "title");
        default:
            return [];
    }
}