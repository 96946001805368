import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import './ingredients.scss';
import classNames from "classnames";
import { PlannerContext } from "../../appContext";

type TIngredientCheckboxProps = {
  ingredient: string;
  show?: "CHECKED" | "UNCHECKED" | "ALL";
  idx: number;
  recipeId: number;
}

type TPlannerIngredientsProps = {
  recipeId: number;
  ingredients: Array<string>
  hide?: boolean;
  className?: string;
}

const IngredientCheckbox = ({ingredient, show = "ALL", idx, recipeId}: TIngredientCheckboxProps) => {
    const { currentPlanner, toggleIngredientCheck } = useContext(PlannerContext);
    const plannerChecked = useMemo(() => {
        if (!(recipeId in currentPlanner.groceries)) {
            return false;
        }

        const checkedSet = currentPlanner.stage === "SHOPPING" ?  
          currentPlanner.groceries[recipeId].shopping :
          currentPlanner.groceries[recipeId].prepping;
        return idx in checkedSet;
    }, [currentPlanner.groceries, recipeId, idx]);

    const [checked, setChecked] = useState(plannerChecked);

  const onClick = useCallback(() => {
    setChecked(!checked);

    const timeout = setTimeout(() => {
        // console.log("toggle");
        toggleIngredientCheck({recipeId, ingredientIdx: idx});
    }, 500);

    return () => {
        clearTimeout(timeout);
    }
  }, [currentPlanner.groceries[recipeId], 
    recipeId, 
    idx,
    toggleIngredientCheck, 
    checked, 
    setChecked]);

useEffect(() => {
    setChecked(plannerChecked);
}, [plannerChecked, setChecked])

  const onChange = () => {
    return;
  }

  if (plannerChecked && show === "UNCHECKED") {
    return null;
  }

  if (!plannerChecked && show === "CHECKED") {
    return null;
  }

  return <div key={ingredient} className={classNames("ingredient-row", checked !== plannerChecked ? "disappearing" : "", checked ? "checked" : "")}>
    <label className={"ingredient-row"}>
      <div className="ingredient-row-wrapper">
        <div>
          <input className={"ingredient-row"} key={ingredient} onClick={onClick} onChange={onChange} type="checkbox" value={ingredient} checked={checked} />
        </div>
        <div>
          <span className={checked ? 'checked' : undefined}>{ingredient}</span>
        </div>
      </div>
    </label>
  </div>
}

export const PlannerIngredients = ({recipeId, ingredients, hide, className}: TPlannerIngredientsProps) => {
    const { currentPlanner, reset } = useContext(PlannerContext);
    const checkedCount = useMemo(() => {
        if (recipeId in currentPlanner.groceries) {
          const checkedSet = currentPlanner.stage === "SHOPPING" ?  
          currentPlanner.groceries[recipeId].shopping :
          currentPlanner.groceries[recipeId].prepping;
          
            return Object.keys(checkedSet).length;
        }
        return 0;
    }, [recipeId, currentPlanner.groceries]);
    const [hideChecked, setHideChecked] = useState(true);
  return <div className={"ingredients-container"}>
    {<div className={classNames("ingredients-list-container", className, hide ? "hide" : "")}>
      <div className={"ingredients-list"}>
        {ingredients.map((ingredient, idx) => <IngredientCheckbox 
            key={recipeId + ': ' + idx} 
            ingredient={ingredient} 
            idx={idx}
            recipeId={recipeId}
            show="UNCHECKED"/>)}
      </div>
    </div>}
    {checkedCount > 0 &&
    <div className={classNames("ingredient-display", hide ? "hide" : "")}>
        <div className="hide-or-show" onClick={() => setHideChecked(!hideChecked)}>
            {hideChecked ? "Show checked ingredients" : "Hide checked ingredients"}
        </div>
        <div className="reset" onClick={() => reset(recipeId)}>
            Reset
        </div>
    </div>
    }
    {<div className={classNames("ingredients-list-container", className, (hide || hideChecked) ? "hide" : "")}>
      <div className={"ingredients-list"}>
        {!hideChecked && ingredients.map((ingredient, idx) => <IngredientCheckbox 
            key={recipeId + ': ' + idx} 
            ingredient={ingredient} 
            idx={idx} 
            recipeId={recipeId}
            show="CHECKED"/>)}
      </div>
    </div>}
  </div>;
}