import React, { useCallback } from "react";
import "./titleBar.scss";
import deckhouseUserImg from "../../assets/deckhouseUser.jpeg";
import { TUserContext } from "../../appContext";
import { UserPicturePlaceholder } from "./UserPicturePlaceholder";
import { useNavigate } from "react-router-dom";

export const TitleBar = ({userContext}: {userContext: TUserContext}) => {
    const navigate = useNavigate();

    const onClickUser = useCallback(() => {
        if (!userContext.loggedIn) {
            userContext.setShowModal({prompt: "Hello and welcome."});
            return;
        }

        navigate('/settings');
    }, [userContext.loggedIn, userContext.setShowModal, navigate]);

    return <div className="title-bar-container">
        <div className="title-bar-left">
            <div className="title-bar">
                Hello, {userContext.loggedIn ? 
                        (userContext.user.firstName || userContext.user.username ) :
                        "Guest"}
            </div>
            <div className="subtitle-bar">
                What do you want to cook today?
            </div>
        </div>
        <div className="title-bar-right">
            <UserPicturePlaceholder 
                onClick={onClickUser} 
                user={userContext.loggedIn ? userContext.user : undefined} 
                size={50} 
                textSize={28} />
        </div>
</div>;
}