import React, { useCallback, useState } from "react";
import { TLoggedInUser } from "../../types/user";
import deckhouseUserImg from "../../assets/deckhouseUser.jpeg";
import addImageSvg from "../../assets/addImage.svg";
import './profilePictureSettings.scss';
import { useDropzone } from "react-dropzone";
import { getCsrfToken } from "../utils";
import { UserPicturePlaceholder } from "./UserPicturePlaceholder";

type TProfilePictureSettingsProps = {
    user: TLoggedInUser;
    refreshCurrentUser: () => void;
}

type FileWithPreview = File & { preview?: string };

export const ProfilePictureSettings = ({user, refreshCurrentUser}: TProfilePictureSettingsProps) => {
    const [preview, setPreview] = useState<FileWithPreview | null>(null);

    const onDrop = useCallback((acceptedFiles: FileWithPreview[]) => {
        if (acceptedFiles.length === 0) {
            console.log("no files accepted")
            return;
        }

        setPreview(
            Object.assign(acceptedFiles[0], {
                preview: URL.createObjectURL(acceptedFiles[0])
            })
        );
    }, []);

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        maxFiles: 1
    });

    const updateProfilePicture = useCallback(() => {
        if (!preview) {
            return;
        }

        const formData = new FormData();
        formData.append("profileImage", preview as File);

        const csrfToken = getCsrfToken();

        fetch('/api/current_user/profile_image', {
            method: "POST", 
            headers: {
                "X-CSRFToken": csrfToken ?? "",                
            },
            body: formData
        }).then(() => {
            setPreview(null);
            refreshCurrentUser();
        });
    }, [preview, refreshCurrentUser, setPreview]);

    return <div className="profile-picture">
        <div className="profile-picture-content">
            {!preview && <div className="camera-container" {...getRootProps()}>
                <input {...getInputProps()} />
                <img className="camera" src={addImageSvg} alt="edit profile picture" />
            </div>}
            {preview && <div className="confirm-container">
                <div className="confirm" onClick={updateProfilePicture}>Confirm</div>
                <div className="cancel" onClick={() => setPreview(null)}>Cancel</div>
            </div>}
            <UserPicturePlaceholder
                preview={preview?.preview} 
                user={user} 
                size={120} 
                textSize={56} />
        </div>
    </div>;
}