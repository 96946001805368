import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {BrowserRouter, Routes, Route, Outlet, Link, useLocation, matchPath, useNavigate, useParams } from "react-router-dom"
import { useSwipeable } from "react-swipeable";
import './betaApp.scss';
import { RecipePanels } from "./RecipePanel";
import homeSvg from "../../assets/home.svg";
import binocularsSvg from "../../assets/binoculars.svg";
import utensilsSvg from "../../assets/utensils.svg";
import bookSvg from "../../assets/book.svg";
import gearSvg from "../../assets/gear.svg";
import { Carousel } from "./Carousel";
import { RecipePage } from "./RecipePage";
import { SearchInput } from "./SearchInput";
import { confetti } from "tsparticles-confetti";
import { SearchPage } from "./SearchPage";
import { AddRecipeFab } from "./AddRecipeFab";
import { getCsrfToken, getPublicCookbookToken, rankedTags } from "../utils";
import { TCurrentUser, defaultUser } from "../../types/user";
import { PlannerContext, SnackBarContext, TPlanner, TPlannerStage, TSnackBar, UserContext } from "../../appContext";
import { UserModal } from "./UserModal";
import { TitleBar } from "./TitleBar";
import { SettingsPage } from "./SettingsPage";
import { CookbooksPage } from "./CookbooksPage";
import { CookbookPage } from "./CookbookPage";
import { PlannerPage } from "./PlannerPage";
import { TRecipeAbridged } from "../../types/cookbook";
import { SnackBar } from "./SnackBar";

const Layout = () => {
    const { setShowModal, showModal, loggedIn } = useContext(UserContext);
    const { currentPlanner } = useContext(PlannerContext);
    const plannerCount = useMemo(() => {
        return Object.keys(currentPlanner.groceries).length;
    }, [currentPlanner.groceries, loggedIn]);
    const navigate = useNavigate();

    const navButtons = [
        { text: "Home", icon: homeSvg, path: "/"},
        { text: "Search", icon: binocularsSvg, path: "/search" },
        { text: "Planner", icon: utensilsSvg, path: "/planner", altPath: "/planner/recipe/:recipeId"},
        { text: "Cookbooks", icon: bookSvg, path: "/cookbooks", altPath: "/cookbook/:cookbookId" },
        { text: "Settings", icon: gearSvg, path: "/settings", loggedInCheck: true},
    ];

    const onClickButton = (button: typeof navButtons[number]) => {
        if (!button.loggedInCheck || loggedIn) {
            return () => navigate(button.path);
        }

        return () => setShowModal({
            prompt: "Log in or sign up to view settings."
        });
    };

    const currentLocation = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        if (!queryParams.get('confetti')) {
            return;
        }
        
        confetti({
            particleCount: 100,
            spread: 70,
            origin: {y: 0.8}
        });
    }, [currentLocation]);

    return <div className={"layout-container" + (showModal ? " user-modal" : "")}>
        <SnackBar />
        <UserModal />
        <div className="outlet-container">
            <Outlet />
        </div>
        <div className="bottom-nav">
            {
                navButtons.map(button => {
                    return <div key={button.text} className={
                        (matchPath(button.path, currentLocation.pathname) || matchPath(button.altPath ?? button.path, currentLocation.pathname)) ? 
                            `bottom-nav-button active ${button.text.toLowerCase()}` 
                            : `bottom-nav-button ${button.text.toLowerCase()}`}>
                        <div onClick={onClickButton(button)}>
                            <div className="icon">
                                {button.text === "Planner" && plannerCount > 0 && 
                                <div className="planner-count">
                                    <div>
                                        {plannerCount}
                                    </div>
                                </div>}
                                <img src={button.icon} alt={button.text} />
                            </div>
                            <div>{button.text}</div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}

const Home = () => {
    const categoryContainerRef = useRef<HTMLElement | null>(null);
    const userContext = useContext(UserContext);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (e) => {
            // console.log("User swiped left");
            if (categoryContainerRef.current) {
                categoryContainerRef.current.scrollLeft += 250;
            }
        },
        onSwipedRight: (e) => {
            // console.log("User swiped right");
            if (categoryContainerRef.current) {
                categoryContainerRef.current.scrollLeft -= 250;
            }
        }
    });

    const categoryContainerRefPassthrough = (el: HTMLElement | null) => {
        swipeHandlers.ref(el);

        categoryContainerRef.current = el;
    }

    const [allRecipes, setAllRecipes] = useState<Array<TRecipeAbridged>>([]);
    const fetchAndSetAllRecipes = useCallback((opts?: {
        successCallback?: () => void,
    }) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch('/api/recipes_metadata', {
            headers: requestHeaders,
        })
            .then(response => response.json())
            .then(data => {
                const allNewRecipes = data as Array<TRecipeAbridged>;
                if (data) {
                    setAllRecipes(allNewRecipes);
                }

                if (opts?.successCallback) {
                    opts.successCallback();
                }
            });
    }, []);

    useEffect(() => {
        fetchAndSetAllRecipes();
    }, [userContext.loggedIn]);

    const allTags = useMemo(() => rankedTags(allRecipes), [allRecipes])

    const [currentTag, setCurrentTag] = useState<string | null>(null);
    const onClickTag = useCallback((tag: string) => () => {
        if (tag !== currentTag) {
            setCurrentTag(tag);
        } else {
            setCurrentTag(null);
        }
    }, [currentTag, setCurrentTag]);

    const { token } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            return;
        }
        userContext.setShowModal({ prompt: "You've been invited to try out recipe tracker. Hurray~~", inviteToken: token});
        navigate('/', {replace: true})
    }, [token, userContext]);

    return <div className="home-page">
        <AddRecipeFab key="home-page"/>
        <TitleBar userContext={userContext} />
        <SearchInput location="HOME"/>
        {allRecipes.length > 0 && <div className="recent-browse-container">
            <Carousel recipes={allRecipes.length >= 3 ? allRecipes.slice(allRecipes.length - 3).reverse() : allRecipes.reverse()}/>
        </div>}
        <div>
            <div className="heading-bar">
                Category
            </div>
            <div className="category-container" {...swipeHandlers} ref={categoryContainerRefPassthrough}>
                {allTags.map(t => 
                    <div className={t === currentTag ? "category-tag selected" : "category-tag"} 
                        key={t}
                        onClick={onClickTag(t)}
                    >
                        {t.charAt(0).toUpperCase() + t.slice(1).toLowerCase()}
                    </div>
                )}
            </div>
        </div>
        <div>
            <div className="heading-bar">
                Browse recipes
            </div>
            <div className="browse-container">
               <RecipePanels recipes={currentTag ? 
                    allRecipes.filter(r => r.tags.map(t => t.name).find(n => n.toLowerCase() === currentTag.toLowerCase())) : allRecipes} />
            </div>
        </div>
    </div>
}

const NoPage = () => {
    return <div>
        <h1>This page is under construction UwU</h1>
    </div>
}

const SharedRedirectPage = () => {
    const { token } = useParams();
    const currentLocation = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const cookbookId = queryParams.get('cookbookId');

        sessionStorage.setItem("cookbookToken", token ?? "");

        navigate(cookbookId ? `/cookbook/${cookbookId}` : "/");
    }, [currentLocation, navigate, token]);

    return <div key="shared-redirect-page"/>
}

export const BetaApp = ({setBeta}: {setBeta: (v: boolean) => void}) => {
    const [user, setUser] = useState<TCurrentUser>(defaultUser());
    const [showModal, setShowModal] = useState<false | { prompt: string}>(false);
    const [loadingCurrentUser, setLoadingCurrentUser] = useState(false);

    const refreshCurrentUser = useCallback(() => {
        setLoadingCurrentUser(true);
        fetch('/api/current_user')
        .then(response => response.json())
        .then(data => {
            const currentUser = data as TCurrentUser;   
            setUser(currentUser);
            // TODO @emikyu - decide if we want the below prompt for users who have not signed up
            // if (!currentUser.loggedIn) {
            //     setShowModal({prompt: "Hello and welcome. Sign up today to track and share your favorite recipes!" })
            // }
            if (currentUser.loggedIn && showModal) {
                setShowModal(false);
            }
            setLoadingCurrentUser(false);
        })
        .catch(_ => {
            console.log("Unable to determine current user");
            setLoadingCurrentUser(false);
        });
    }, [setUser, setShowModal, showModal, setLoadingCurrentUser]);

    useEffect(() => {
        refreshCurrentUser();
    }, []);

    const [currentPlanner, setCurrentPlanner] = useState<TPlanner>
        (JSON.parse(localStorage.getItem('currentPlanner') ?? 
        JSON.stringify({ groceries: {}, stage: "BOOKMARKING" })));

    const logIn = useCallback(({loginName, password}: {loginName: string; password: string}) => {
        const csrfToken = getCsrfToken();
        fetch('/api/current_user', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                action: "LOG_IN",
                loginName,
                password
            })
        }).then(_ => {
            refreshCurrentUser();
            setCurrentPlanner({groceries: {}, stage: "BOOKMARKING"});
        }).catch(_ => {
            console.log("Error when calling login endpoint")
        });
    }, [refreshCurrentUser]);

    const logOut = useCallback((successCallback?: () => void) => {
        const csrfToken = getCsrfToken();
        fetch('/api/current_user', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                action: "LOG_OUT",
            })
        }).then(_ => {
            refreshCurrentUser();
            localStorage.removeItem('currentPlanner');
            setCurrentPlanner({ groceries: {}, stage: "BOOKMARKING" });

            if (successCallback) {
                successCallback();
            }
        }).catch(_ => {
            console.log("Error when calling logout endpoint")
        });
    }, [refreshCurrentUser, setCurrentPlanner]);

    const signUp = useCallback(({firstName, email, password, inviteToken}: 
        {firstName: string; email: string; password: string, inviteToken: string}) => {
        const csrfToken = getCsrfToken();
        fetch('/api/current_user', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken ?? "",
            },
            body: JSON.stringify({
                action: "SIGN_UP",
                firstName,
                email,
                password,
                inviteToken
            })
        }).then(_ => {
            refreshCurrentUser();
        }).catch(_ => {
            console.log("Error when calling login endpoint")
        });
    }, [refreshCurrentUser]);

    const addToPlanner = useCallback((recipeId: number) => {
        setCurrentPlanner(currentPlanner => {
            const newPlanner: TPlanner = { 
                groceries: {
                    ...currentPlanner.groceries
                }, 
                stage: currentPlanner.stage 
            };
            
            if (!(recipeId in newPlanner.groceries)) {
                newPlanner.groceries[recipeId] = { quantity: 0, shopping: {}, prepping: {}, cooked: false, cookingStep: null};
            }
            newPlanner.groceries[recipeId].quantity += 1;
    
            localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
            return newPlanner;
        });
    }, [setCurrentPlanner]);

    const removeFromPlanner = useCallback((recipeId: number) => {
        setCurrentPlanner(currentPlanner => {
            const newPlanner: TPlanner = { 
                groceries: {...currentPlanner.groceries}, 
                stage: currentPlanner.stage 
            };
    
            if (recipeId in newPlanner.groceries) {
                delete newPlanner.groceries[recipeId];
            }
    
            if (Object.keys(newPlanner.groceries).length === 0) {
                newPlanner.stage = "BOOKMARKING";
            }
    
            localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
            return newPlanner;
        });
    }, [setCurrentPlanner]);

    const clearPlanner = useCallback(() => {
        const newPlanner: TPlanner = { groceries: {}, stage: "BOOKMARKING" };
        setCurrentPlanner(newPlanner);
        localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
    }, [setCurrentPlanner]);

    const setPlannerStage = useCallback((stage: TPlannerStage) => {
        const newPlanner: TPlanner = { 
            groceries: {...currentPlanner.groceries}, 
            stage,
        };

        setCurrentPlanner(newPlanner);
        localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
    }, [currentPlanner, setCurrentPlanner]);

    const toggleIngredientCheck = useCallback(({recipeId, ingredientIdx}: {recipeId: number; ingredientIdx: number}) => {
        // toggle check list depending on which stage we're in
        setCurrentPlanner(currentPlanner => {
            if (!(recipeId in currentPlanner.groceries) ||
            currentPlanner.stage === "BOOKMARKING" ||
            currentPlanner.stage === "NOMMING") {
                return currentPlanner;
            }

            const newPlanner: TPlanner = { 
                groceries: {...currentPlanner.groceries}, 
                stage: currentPlanner.stage,
            };
    
            // console.log(newPlanner);

            newPlanner.groceries[recipeId] = {
                quantity: newPlanner.groceries[recipeId].quantity,
                shopping: {...newPlanner.groceries[recipeId].shopping},
                prepping: {...newPlanner.groceries[recipeId].prepping},
                cooked: newPlanner.groceries[recipeId].cooked,
                cookingStep: newPlanner.groceries[recipeId].cookingStep
            };

            const checkList = newPlanner.stage === "SHOPPING" ? 
                newPlanner.groceries[recipeId].shopping : // use the shopping check list
                newPlanner.groceries[recipeId].prepping; // use the cook prep list
    
            if (ingredientIdx in checkList) {
                delete checkList[ingredientIdx];
            } else {
                checkList[ingredientIdx] = true;
            }

            localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
            return newPlanner;
        });
    }, [setCurrentPlanner]);

    // resets specific recipe corresponding to the step, or all recipes if no recipe specified
    // - shopping step: reset all ingredient lists
    // - cooking step: reset all ingredient lists, current step, and cooked info
    const reset = useCallback((recipeId?: number) => {
        const newPlanner: TPlanner = { 
            groceries: {...currentPlanner.groceries}, 
            stage: currentPlanner.stage,
        };

        if (newPlanner.stage !== "SHOPPING" && newPlanner.stage !== "COOKING") {
            return;
        }


        if (recipeId) {
            if (recipeId in newPlanner.groceries) {
                newPlanner.groceries[recipeId] = {
                    quantity: newPlanner.groceries[recipeId].quantity,
                    shopping: {...newPlanner.groceries[recipeId].shopping},
                    prepping: {...newPlanner.groceries[recipeId].prepping},
                    cooked: newPlanner.groceries[recipeId].cooked,
                    cookingStep: newPlanner.groceries[recipeId].cookingStep
                }

                if (newPlanner.stage === "SHOPPING") {
                    newPlanner.groceries[recipeId].shopping = {};
                } else {
                    newPlanner.groceries[recipeId].prepping = {};
                    newPlanner.groceries[recipeId].cooked = false;
                    newPlanner.groceries[recipeId].cookingStep = null;
                }
            }
        } else {
            Object.keys(newPlanner.groceries).forEach(k => {
                const id = parseInt(k);
                newPlanner.groceries[id] = {
                    quantity: newPlanner.groceries[id].quantity,
                    shopping: {...newPlanner.groceries[id].shopping},
                    prepping: {...newPlanner.groceries[id].prepping},
                    cooked: newPlanner.groceries[id].cooked,
                    cookingStep: newPlanner.groceries[id].cookingStep
                }

                if (newPlanner.stage === "SHOPPING") {
                    newPlanner.groceries[id].shopping = {};
                } else {
                    newPlanner.groceries[id].prepping = {};
                    newPlanner.groceries[id].cooked = false;
                    newPlanner.groceries[id].cookingStep = null;
                }
            });
        }

        setCurrentPlanner(newPlanner);
        localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
    }, [currentPlanner.groceries, setCurrentPlanner]);

    const setCookingStep = useCallback(({recipeId, step}: {recipeId:number; step: number | null}) => {
        setCurrentPlanner((currentPlanner) => {
            if (!(recipeId in currentPlanner.groceries) || currentPlanner.stage !== "COOKING") {
                return currentPlanner;
            }

            const newPlanner: TPlanner = { 
                groceries: {...currentPlanner.groceries}, 
                stage: currentPlanner.stage,
            };

            newPlanner.groceries[recipeId] = {
                quantity: newPlanner.groceries[recipeId].quantity,
                shopping: {...newPlanner.groceries[recipeId].shopping},
                prepping: {...newPlanner.groceries[recipeId].prepping},
                cooked: newPlanner.groceries[recipeId].cooked,
                cookingStep: step
            };
            
            localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
            return newPlanner;
        });
    }, [setCurrentPlanner]);

    const toggleDone = useCallback((recipeId: number) => {
        setCurrentPlanner(currentPlanner => {
            if (!(recipeId in currentPlanner.groceries)) {
                return currentPlanner;
            }

            const newPlanner: TPlanner = { 
                groceries: {...currentPlanner.groceries}, 
                stage: currentPlanner.stage,
            };

            newPlanner.groceries[recipeId] = {
                quantity: newPlanner.groceries[recipeId].quantity,
                shopping: {...newPlanner.groceries[recipeId].shopping},
                prepping: {...newPlanner.groceries[recipeId].prepping},
                cooked: !newPlanner.groceries[recipeId].cooked,
                cookingStep: newPlanner.groceries[recipeId].cookingStep
            };

            localStorage.setItem('currentPlanner', JSON.stringify(newPlanner));
            return newPlanner;
        });
    }, [setCurrentPlanner]);

    // uncomment fake snacks below for testing
    const [snacks, setSnacks] = useState<TSnackBar[]>([
            // {id: "1", message: "snack number 1"},
            // {id: "2", message: "snack number 2"}
    ]);

    const addSnack = useCallback((snack: TSnackBar) => {
        setSnacks(prevSnacks => {
            // always only keep one snack with same id in stack
            // but ensure order is kept
            const newSnacks = prevSnacks.filter(s => s.id !== snack.id);
            newSnacks.push({...snack});
            return newSnacks;
        });
    }, [setSnacks]);

    const popSnack = useCallback((id: string) => {
        setSnacks(prevSnacks => {
            return prevSnacks.filter(s => s.id !== id);
        });
    }, [setSnacks]);

    return <UserContext.Provider value={{
        ...user, 
        refreshCurrentUser, 
        loadingCurrentUser,
        logIn, 
        logOut, 
        signUp, 
        showModal, 
        setShowModal}}>
        <PlannerContext.Provider value={{
            currentPlanner, 
            addToPlanner, 
            removeFromPlanner, 
            setPlannerStage, 
            toggleIngredientCheck,
            setCookingStep,
            toggleDone,
            reset,
            clearPlanner}}>
                <SnackBarContext.Provider value={{
                    snacks,
                    addSnack,
                    popSnack
                }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/shared/:token" element={<SharedRedirectPage />}/>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Home />}/>
                                <Route path="/invited/:token" element={<Home />}/>
                                <Route path="/recipe/:recipeId" element={<RecipePage />} />
                                <Route path="/search" element={<SearchPage />} />
                                <Route path="/planner/recipe/:recipeId" element={<RecipePage inPlanner={true}/>}/>
                                <Route path="/planner" element={<PlannerPage />}/>
                                <Route path="/cookbooks" element={<CookbooksPage />}/>
                                <Route path="/cookbook/:cookbookId" element={<CookbookPage />} />
                                <Route path="/settings" element={<SettingsPage setBeta={setBeta}/>}/>
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </SnackBarContext.Provider>
        </PlannerContext.Provider>
    </UserContext.Provider>;
}