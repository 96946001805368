import React from "react";
import { TRecipe } from "../types/recipe";
import placeholder from "../assets/cookingPot.svg";
import "./recipePanel.scss";

type TRecipePanelProps = {
    recipe: TRecipe;
    selectRecipe: (r: TRecipe) => void;
}

type TRecipePanelsProps = {
    recipes: Array<TRecipe>;
    selectRecipe: (r: TRecipe) => void;
}

const RecipePanel = ({selectRecipe, recipe} : TRecipePanelProps) => {
    const onClick = () => {
        selectRecipe(recipe);
    };

    return <div className="recipe-panel" onClick={onClick}>
        <div className="recipe-panel-image">
            {
                recipe.image ? 
                    <img src={`/recipe_images/` + recipe.image}/> : 
                    <img className="placeholder-image" src={placeholder}/>
            }
        </div>
        <div className="recipe-panel-title">
            {recipe.title}
        </div>
    </div>;
};

export const RecipePanels = ({recipes, selectRecipe} : TRecipePanelsProps) => {
    return <div className="recipe-panels-container">
        {recipes.map(recipe => <RecipePanel key={recipe.id} selectRecipe={selectRecipe} recipe={recipe}/>)}
    </div>
};