import React from "react";
import './photoGallery.scss';
import { ImageDropzone } from "./ImageDropzone";

type TPhotoGalleryProps = {
    recipeId: number;
    recipeImage?: string;
    onUploadAttemptComplete: (isCover: boolean) => void;
}

export const PhotoGallery = ({ 
    recipeId, 
    recipeImage, 
    onUploadAttemptComplete }: TPhotoGalleryProps) => {

    return <div>
        {recipeImage ?
            <img src={'/recipe_images/' + recipeImage} className={`recipe-image`} /> :
            <ImageDropzone 
                recipeId={recipeId}
                className="recipe-image-missing"
                onUploadAttemptComplete={onUploadAttemptComplete}
                autoOverrideCover={true}
            />
        }
    </div>;
}