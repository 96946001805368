import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PlannerContext, TPlannerStage, UserContext, plannerStages } from "../../appContext";
import './plannerPage.scss'
import { TRecipeAbridged } from "../../types/cookbook";
import { getPublicCookbookToken } from "../utils";
import classNames from "classnames";
import { RecipeRow } from "./RecipesList";
import { PlannerIngredients } from "./PlannerIngredients";
import kebabSvg from "../../assets/kebab.svg";
import { StartNextStageFab } from "./StartNextStageFab";
import { Link } from "react-router-dom";

type TPlannerPageProps = {
}

export const PlannerPage = ({}: TPlannerPageProps) => {
    const userContext = useContext(UserContext);
    const { currentPlanner, clearPlanner, setPlannerStage, reset } = useContext(PlannerContext);

    const [allRecipes, setAllRecipes] = useState<Record<number, TRecipeAbridged> | null>(null);
    const fetchAndSetAllRecipes = useCallback((opts?: {
        successCallback?: () => void,
    }) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }

        fetch('/api/recipes_metadata', { headers: requestHeaders })
            .then(response => response.json())
            .then(data => {
                const allNewRecipes = data as Array<TRecipeAbridged>;
                const result: Record<number, TRecipeAbridged> = {};

                if (allNewRecipes) {
                    allNewRecipes.forEach(r => {
                        result[r.id] = r;
                    })
                    setAllRecipes(result);
                }

                if (opts?.successCallback) {
                    opts.successCallback();
                }
            });
    }, [setAllRecipes]);

    const currentPlannerRecipes: Array<{recipe: TRecipeAbridged, count: number}> | null = useMemo(() => {
        if (!allRecipes) {
            return null;
        }
        return Object.entries(currentPlanner.groceries)
            .map(([recipeId, info]) => ({ recipe: allRecipes[parseInt(recipeId)], count: info.quantity}));
    }, [allRecipes, currentPlanner.groceries]);

    useEffect(() => {
        fetchAndSetAllRecipes();
    }, [userContext.loggedIn]);

    const progressIdx = useMemo(() => {
        return plannerStages.findIndex(s => s === currentPlanner.stage);
    }, [currentPlanner.stage]);

    const progressPt = useMemo(() => {
        if (!currentPlannerRecipes) {
            return 0;
        }
        
        if (currentPlanner.stage === "BOOKMARKING") {
            return 50 / (plannerStages.length - 1);
        }

        const base = progressIdx * 100/(plannerStages.length - 1);

        if (currentPlanner.stage === "SHOPPING") {
            const totalChecked = Object.values(currentPlanner.groceries).reduce((total, currentRecipe) => {
                return Object.keys(currentRecipe.shopping).length + total;
            }, 0);

            const totalIngredients = currentPlannerRecipes.reduce((total, r) => {
                return r.recipe.ingredients.length + total;
            }, 0);

            const delta = (totalChecked * 100 / totalIngredients)/(plannerStages.length - 1);

            return base + delta;
        }

        return base;

    }, [progressIdx, currentPlanner.stage, currentPlanner.groceries, currentPlannerRecipes]);

    const nextStage: TPlannerStage | null = useMemo(() => {
        if (progressIdx === plannerStages.length - 1) {
            return null;
        }

        const stage = plannerStages[progressIdx + 1];
        return stage;
    }, [progressIdx]);

    const showIngredients = currentPlanner.stage === "SHOPPING";

    const isComplete = useCallback((recipe: TRecipeAbridged) => {
        if (currentPlanner.stage !== "SHOPPING" && currentPlanner.stage !== "COOKING") {
            return false;
        }

        if (currentPlanner.stage === "COOKING") {
            return currentPlanner.groceries[recipe.id].cooked;
        }

        const totalChecked = Object.keys(currentPlanner.groceries[recipe.id].shopping).length;
        return totalChecked === recipe.ingredients.length;
    }, [currentPlanner.groceries, currentPlanner.stage]);


    const deprioritizeCompleted = useCallback((a: {recipe: TRecipeAbridged}, b: { recipe: TRecipeAbridged }) => {
        const aCompleted = isComplete(a.recipe);
        const bCompleted = isComplete(b.recipe);
        if ((aCompleted && bCompleted) || (!aCompleted && !bCompleted)) {
            return a.recipe.title < b.recipe.title ? -1 : 1;
        }

        return aCompleted ? 1 : -1;
    }, [isComplete]);

    const [kebabOpen, setKebabOpen] = useState(false);

    useEffect(() => {
        if (!window) {
            return;
        }

        const scrollListener = () => {
            if (kebabOpen) {
                setKebabOpen(false);
            }
        };

        window.addEventListener("scroll", scrollListener);

        return () => window.removeEventListener("scroll", scrollListener);
    }, [!window, kebabOpen, setKebabOpen]);
    
    return <div className="planner-page">
            {kebabOpen && <div className="modal" onClick={() => setKebabOpen(false)}/>}
            <StartNextStageFab 
                nextStage={nextStage} 
                promoteStage={() => nextStage && setPlannerStage(nextStage)}
                show={!!nextStage && !!Object.keys(currentPlanner.groceries).length}
            />
            <div>
                <div className="title-bar-container">
                    <div className="title-bar-left">
                        <div className="title-bar">
                            <div>
                                Meal Planner
                            </div>
                            <div className="title-bar-kebab" onClick={(e) => {
                                    e.stopPropagation();
                                    // console.log("Clicked kebab");
                                    setKebabOpen(!kebabOpen);
                                }}>
                                <div className={classNames("kebab-dropdown", kebabOpen ? "show" : "")}>
                                    <div onClick={(e) => 
                                    {
                                        e.stopPropagation();
                                        kebabOpen && reset();
                                        setKebabOpen(false);
                                    }} 
                                        className={(!currentPlannerRecipes || currentPlannerRecipes.length === 0 || 
                                            currentPlanner.stage === "NOMMING" || 
                                            currentPlanner.stage === "BOOKMARKING") ? "disabled" : ""}
                                    >
                                        Reset Step
                                    </div>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        kebabOpen && clearPlanner();
                                        setKebabOpen(false);
                                    }}
                                        className={(!currentPlannerRecipes || currentPlannerRecipes.length === 0) ? "disabled" : ""}>
                                        Clear Planner
                                    </div>
                                </div>
                                <img src={kebabSvg} alt="open menu" />
                            </div>
                        </div>
                        <div className="subtitle-bar">
                            Let's cook some good noms today!
                        </div>
                    </div>
                </div>
                <div className="progress-container">
                    <div className="progress">
                        {
                            plannerStages.map((stage, idx) => 
                                <div 
                                    key={`dot-${stage}`} 
                                    className={classNames("progress-marker",  
                                        idx <= progressIdx ? "completed" : undefined,
                                        idx === progressIdx ? "current" : undefined)}
                                >
                                    <div 
                                        className={"progress-dot"} 
                                    />
                                    <div className="label" onClick={() => {
                                        if (!currentPlannerRecipes || currentPlannerRecipes.length === 0) {
                                            return;
                                        }
                                        setPlannerStage(stage);
                                    }}>
                                        {stage[0].toUpperCase() + stage.slice(1).toLowerCase()}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="progress-bar">
                        <div className="complete" style={{minWidth: `${progressPt}%`}}/>
                        <div className="incomplete" style={{minWidth: `${100 - progressPt}%`}} />
                    </div>
                </div>
            </div>
            <div className={classNames("recipes-list-container", 
                "on-planner-page", showIngredients ? 
                "show-ingredients" : "")}>
                {currentPlannerRecipes && currentPlannerRecipes.sort(deprioritizeCompleted).map(r =>
                    <div key={r.recipe.title} 
                        className={classNames("recipe-plan-container", 
                            isComplete(r.recipe) ? "is-complete" : undefined)}>
                        <RecipeRow recipe={r.recipe} onPlanner={true} />
                        <PlannerIngredients 
                            recipeId={r.recipe.id} 
                            ingredients={r.recipe.ingredients}
                            className={"on-planner-page"}
                            hide={!showIngredients}
                        />
                    </div>)}
                {currentPlannerRecipes && currentPlannerRecipes.length === 0 && <div className="no-recipes-added">
                    You have not yet added any recipes to your meal planner. 
                    <br/> <br/>
                    Go to <Link to="/">Home</Link> or <Link to="/search">Search</Link>, and add recipes by clicking on the bookmark icon.
                </div>}
                {currentPlanner.stage === "NOMMING" && <div className="clear-planner" onClick={clearPlanner}>
                    Clear planner & Start over
                </div>}
            </div>
        </div>;
}