import React, { useEffect, useState, useMemo, useCallback} from "react";
import { RecipeList } from "./components/RecipeList";
import { RecipeContent } from "./components/RecipeContent";
import { TitleBar } from "./components/TitleBar";
import { TRecipe, TRecipeFilter } from "./types/recipe";
import "./appContent.scss";
import { BetaApp } from "./components/beta/BetaApp";

const App = () => {
  const [beta, setBeta] = useState(true);
  const [allRecipes, setAllRecipes] = useState<Array<TRecipe>>([]);

  const [selectedRecipe, setSelectedRecipe] = useState<TRecipe | undefined>(undefined);

  useEffect(() => {
    if (allRecipes.length === 0 || beta) {
      return;
    }

    const url = new  URL(window.location.href);
    const recipeId = url.searchParams.get('recipeId');
    if (!recipeId) {
      return;
    }

    const recipe = allRecipes.find(r => r.id.toString() === recipeId);
    if (!recipe) {
      url.searchParams.delete('recipeId');
      document.title = "Recipe Tracker";
      window.history.pushState( {} , '' , url); 
      setSelectedRecipe(undefined);
      return;
    }

    setSelectedRecipe(recipe);
    document.title = recipe.title;
  }, [allRecipes.length === 0, beta]);

  const selectRecipe = (recipe?: TRecipe) => {
    setSelectedRecipe(recipe);
    const url = new URL(window.location.href);
    if (recipe) {
      url.searchParams.set('recipeId' , recipe?.id.toString()) ; 
    } else {
      url.searchParams.delete('recipeId');
    }
    window.history.pushState( {} , '' , url); 
    document.title = recipe?.title ?? "Recipe Tracker";
  }

  const [isCookingMode, setIsCookingMode] = useState(false);

  const [isVerticalSplit, setIsVerticalSplit] = useState(false);

  const [recipeFilter, setRecipeFilter] = useState<Array<TRecipeFilter>>([]);

  const updateRecipesFilter = (filter: string, opts?: { ingredientsLimit: number; timeLimit: number }) => {
    const numericalFilters: Array<TRecipeFilter> = [];
    if (opts) {
      numericalFilters.push({
        kind: 'INGREDIENTS_COUNT',
        query: opts.ingredientsLimit,
      });
      numericalFilters.push({
        kind: 'TIME',
        query: opts.timeLimit
      });
    }

    // // process filter - LEGACY
    // if (filter.includes('#')) {
    //   const processedFilters = filter
    //     .split('#')
    //     .map(f => f.trim())
    //     .filter(f => f !== '')
    //     .map(f => ({
    //       kind: 'TAG' as const,
    //       query: f.trim()
    //     }));
    //   setRecipeFilter([...processedFilters, ...numericalFilters]);
    //   console.log(processedFilters)
    // } else {
    //   setRecipeFilter([{
    //     kind: 'TITLE',
    //     query: filter.trim()
    //   }, ...numericalFilters]);
    // }

    const processedFilter: Array<TRecipeFilter> = filter
      .split(' ')
      .map(f => f.trim())
      .filter(f => f !== '')
      .map(f => {
        if (f.startsWith('#')) {
          return {
            kind: 'TAG',
            query: f.replace(/^#/, '')
          }
        } else if (f.startsWith('ing:')) {
          return {
            kind: 'INGREDIENT',
            query: f.replace(/^ing:/, '')
          }
        } else {
          return {
            kind: 'TITLE',
            query: f
          }
        }
      });

    // console.log(processedFilter)
    setRecipeFilter([...processedFilter, ...numericalFilters]);
  }

  // Filter recipes down to what's shown by the filter - using accumulator
  const recipes = recipeFilter.reduce((prevRecipes, currentFilter) => {
    switch (currentFilter.kind) {
      case 'TITLE':
        // filter down to titles that match query
        return prevRecipes.filter(recipe => {
          return recipe.title.toLowerCase().includes(currentFilter.query.toLowerCase());
        });
      case 'INGREDIENTS_COUNT':
        return prevRecipes.filter(recipe => {
          return recipe.ingredients.length <= currentFilter.query;
        });
      case 'TIME':
        return prevRecipes.filter(recipe => {
          return (recipe.total_time ?? 0) <= currentFilter.query
        })
      case 'TAG':
        // filter down to tags that match query
        return prevRecipes.filter(recipe => {
          return recipe.tags.map(t => t.name.toLowerCase()).includes(currentFilter.query.toLowerCase());
        });
      case 'INGREDIENT':
        // filter down to tags that match query
        // console.log(prevRecipes);
        return prevRecipes.filter(recipe => {
          return !!recipe.ingredients.find(i => i.toLowerCase().includes(currentFilter.query.toLowerCase()));
        });
      default:
        // should not get to here, but will just no-op if here
        return prevRecipes;
    }
  }, allRecipes);

  const fetchAndSetAllRecipes = useCallback((opts?: {
    successCallback?: () => void, 
    selectRecipeId?: number,
  }) => {
    fetch('/api/recipes')
      .then(response => response.json())
      .then(data => {
        const allNewRecipes = data as Array<TRecipe>;
        if (data ) {
          setAllRecipes(allNewRecipes);
        }

        if (opts?.successCallback) {
          opts.successCallback();
        }

        if (opts?.selectRecipeId) {
          setSelectedRecipe(allNewRecipes.find(recipe => recipe.id === opts.selectRecipeId));
        }
      });
  }, []);

  useEffect(() => {
    if (beta) {
      return;
    }
    fetchAndSetAllRecipes();
  }, [beta]);

  const maxIngredientsCount = useMemo(() => 
    Math.max(...allRecipes.map(r => r.ingredients.length)), 
    [allRecipes]);
  const maxCookingTime = useMemo(() => 
    Math.max(...allRecipes.map(r => r.total_time ?? 0)),
    [allRecipes]);

  if (beta) {
    return  <main key="beta" className="beta-container">
      <BetaApp setBeta={setBeta}/>
    </main>
  }

  return (
    <main className="container" key="non-beta">
      <div className="beta-button-container">
        <button onClick={() => setBeta(true)}>Test Beta</button>
      </div>
      <TitleBar
        setIsVerticalSplit={setIsVerticalSplit}
        isVerticalSplit={isVerticalSplit}
        setIsCookingMode={setIsCookingMode}
        isCookingMode={isCookingMode}
        fetchAndSetAllRecipes={fetchAndSetAllRecipes}
        selectRecipe={selectRecipe}
        updateRecipesFilter={updateRecipesFilter}
        maxIngredientsCount={maxIngredientsCount}
        maxCookingTime={maxCookingTime}
      />
      <div className="row">
        <div className="col-md-6 col-sm-10 mx-auto p-0">
          <div className="card p-3">
            <div className={(isCookingMode ? "is-cooking " : " ") + "content"}>
                <RecipeList isCookingMode={isCookingMode} recipes={recipes} selectedRecipe={selectedRecipe} selectRecipe={selectRecipe}/>
                <RecipeContent 
                  key={selectedRecipe?.id}
                  isVerticalSplit={isVerticalSplit} 
                  isCookingMode={isCookingMode} 
                  allRecipes={recipes} 
                  recipe={selectedRecipe}
                  selectRecipe={selectRecipe}
                  fetchAndSetAllRecipes={fetchAndSetAllRecipes}
                />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;