import React, { useCallback, useContext, useMemo } from "react";
import './recipesList.scss';
import { RecipePanel } from "./RecipePanel";
import { minutesToPrepTime } from "../utils";
import { TCookbook, TRecipeAbridged } from "../../types/cookbook";
import { useNavigate } from "react-router-dom";
import { TRecipeSortOrder, sortedRecipes } from "../../types/recipe";
import { PlannerContext, SnackBarContext } from "../../appContext";
import bookmarkSvg from "../../assets/bookmarkBlack.svg";
import bookmarkSolidSvg from "../../assets/bookmarkSolidBlack.svg";

type TRecipesListProps = {
    cookbook: TCookbook;
    sortOrder: TRecipeSortOrder;
}

type TRecipeRowProps = {
    recipe: TRecipeAbridged;
    sortOrder?: TRecipeSortOrder;
    onPlanner?: boolean;
    hideAddBy?: boolean;
    showBookmark?: boolean;
}

export const RecipeRow = ({recipe, sortOrder, onPlanner, hideAddBy, showBookmark}: TRecipeRowProps) => {
    const navigate = useNavigate();
    const link = onPlanner ? `/planner/recipe/${recipe.id}` : sortOrder ? `/recipe/${recipe.id}?cookbook=${sortOrder}` : `/recipe/${recipe.id}`

    const { currentPlanner, removeFromPlanner, toggleDone, toggleIngredientCheck, addToPlanner} = useContext(PlannerContext);
    const { addSnack } = useContext(SnackBarContext);

    const removeRecipe = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (!onPlanner) {
            return;
        }

        removeFromPlanner(recipe.id);
        addSnack({
            id: `remove-from-planner-${recipe.id}`,
            message: `Recipe '${recipe.title}' was removed from your planner`,
            action: {
                label: "undo",
                callback: () => {
                    addToPlanner(recipe.id);
                }
            },
            displayTime: 6 * 1000 // longer display time since there's an action
        });
    }, [onPlanner, removeFromPlanner, recipe, addSnack]);

    const isComplete = useMemo(() => {
        if (!onPlanner) {
            return false;
        }

        if (currentPlanner.stage === "NOMMING") {
            return true;
        }

        if (currentPlanner.stage === "BOOKMARKING") {
            return false;
        }

        if (currentPlanner.stage === "COOKING") {
            return currentPlanner.groceries[recipe.id].cooked;
        }

        return Object.keys(currentPlanner.groceries[recipe.id].shopping).length === recipe.ingredients.length;
    }, [currentPlanner, recipe, onPlanner]);


    const markRecipeAsComplete = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (!onPlanner) {
            return;
        }

        if (currentPlanner.stage === "COOKING") {
            toggleDone(recipe.id);   
        }

        if (currentPlanner.stage === "SHOPPING") {
            recipe.ingredients.forEach((_, idx) => 
                !(idx in currentPlanner.groceries[recipe.id].shopping) && toggleIngredientCheck({recipeId: recipe.id, ingredientIdx: idx}))
        }
    }, [onPlanner, isComplete, currentPlanner, recipe]);

    const isBookmarked = recipe.id in currentPlanner.groceries;

    return <div className="recipe-row-container" onClick={() => navigate(link)}>
        <RecipePanel hidePlanner={true} recipe={recipe} hideText={true} className={"on-cookbook-page"}/>
        <div className="recipe-row-details">
            <div>
                <div className="recipe-title">
                    {recipe.title}
                </div>
                <div className="recipe-data">
                    {recipe.total_time ?
                        `${minutesToPrepTime(recipe.total_time)} | ${recipe.ingredients.length} ings` :
                        `${recipe.ingredients.length} ings`}
                </div>
            </div>
            {!onPlanner && !hideAddBy && <div className="uploader">
                Added by: {recipe.uploader.first_name || recipe.uploader.username}
            </div>}
            {onPlanner && currentPlanner.stage !== "NOMMING" && <div className="action">
                {currentPlanner.stage !== "BOOKMARKING" && (!isComplete || currentPlanner.stage === "COOKING") && 
                <div onClick={markRecipeAsComplete}>
                    Mark {isComplete ? "Incomplete" : "Complete" }
                </div>}
                <div onClick={removeRecipe}>
                    Remove
                </div>
            </div>}
            {
                showBookmark && <div className="action">
                    <div className="bookmark" onClick={(e) => {
                        e.stopPropagation();
                        isBookmarked ? removeFromPlanner(recipe.id) : addToPlanner(recipe.id);
                    }}>
                        <img src={isBookmarked ? bookmarkSolidSvg : bookmarkSvg} alt="toggle bookmark" />
                    </div>
                </div>
            }
        </div>
    </div>
}

export const RecipesList = ({cookbook, sortOrder}: TRecipesListProps) => {
    const recipes: TRecipeAbridged[] = useMemo(() => {
        return sortedRecipes(cookbook.recipes, sortOrder)
    }, [cookbook.recipes, sortOrder]);

    return <div className="recipes-list-container">
        {recipes.length === 0 && <div className="empty-list">
            This cookbook is currently empty.
            </div>}
        {recipes.map(r => <RecipeRow sortOrder={sortOrder} key={`recipe-${r.id}-${r.title}`} recipe={r}/>)}
    </div>
}