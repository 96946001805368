import React, { useState } from "react";
import './ingredients.scss';
import classNames from "classnames";

type TIngredientCheckboxProps = {
  ingredient: string;
}

type TIngredientsProps = {
  recipeId: number;
  ingredients: Array<string>
  hide?: boolean;
  className?: string;
}

const IngredientCheckbox = ({ingredient}: TIngredientCheckboxProps) => {
  const [checked, setChecked] = useState(false);
  const onClick = () => {
    setChecked(!checked);
  };
  const onChange = () => {
    return;
  }

  return <div key={ingredient} className={"ingredient-row"}>
    <label className={"ingredient-row"}>
      <div className="ingredient-row-wrapper">
        <div>
          <input className={"ingredient-row"} key={ingredient} onClick={onClick} onChange={onChange} type="checkbox" value={ingredient} checked={checked} />
        </div>
        <div>
          <span className={checked ? 'checked' : undefined}>{ingredient}</span>
        </div>
      </div>
    </label>
  </div>
}

export const Ingredients = ({recipeId, ingredients, hide, className}: TIngredientsProps) => {

  return <div className={"ingredients-container"}>
    {<div className={classNames("ingredients-list-container", className, hide ? "hide" : "")}>
      <div className={"ingredients-list"}>
        {ingredients.map((ingredient, idx) => <IngredientCheckbox key={recipeId + ': ' + idx} ingredient={ingredient} />)}
      </div>
    </div>}
  </div>;
}