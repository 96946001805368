import React, { useEffect, useRef, useState } from "react";
import './carousel.scss';
import placeholder from "../../assets/cookingPot.svg";
import { Link } from "react-router-dom";
import { TRecipeAbridged } from "../../types/cookbook";

type TCarouselProps = {
    recipes: TRecipeAbridged[];
}

const delay = 5000; // delay between changing slides

export const Carousel = ({recipes}: TCarouselProps) => {
    const [index, setIndex] = useState(0);
    const [interacted, setInteracted] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        if (interacted) {
            return;
        }

        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === recipes.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div className="slideshow">
            <div className="slideshow-title">
                Latest additions
            </div>
            <div
                className="slideshowSlider"
                style={recipes.length > 1 ? { transform: `translate3d(${-index * 100}%, 0, 0)` } : undefined}
            >
                {recipes.map((recipe, index) => (
                    <Link to={`/recipe/${recipe.id}`} key={index + recipe.title}>
                        <div className="slide" >
                            <div className="slide-vignette" />
                            <img src={recipe.image ? `/recipe_images/${recipe.image}` : placeholder} alt="" />
                        </div>
                    </Link>
                ))}
            </div>

            {recipes.length > 1 && <div className="slideshowDots">
                {recipes.map((_, idx) => (
                    <div
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                            setInteracted(true);
                            setIndex(idx);
                        }}
                    ></div>
                ))}
            </div>}
        </div>
    );
}
