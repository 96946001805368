import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import './searchPage.scss';
import deckhouseUserImg from "../../assets/deckhouseUser.jpeg";
import { SearchInput } from "./SearchInput";
import { TRecipeFilter, TRecipeSortOrder, recipeSortOrderOptions, sortedRecipes } from "../../types/recipe";
import { RecipePanel } from "./RecipePanel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AddRecipeFab } from "./AddRecipeFab";
import { UserContext } from "../../appContext";
import { TitleBar } from "./TitleBar";
import { TRecipeAbridged } from "../../types/cookbook";
import { getPublicCookbookToken } from "../utils";
import { RecipeRow } from "./RecipesList";
import listViewSvg from "../../assets/listViewPurple.svg";
import tileViewSvg from "../../assets/tileViewPurple.svg";

export const SearchPage = () => {
    const [recipeFilter, setRecipeFilter] = useState<Array<TRecipeFilter>>([]);
    const [allRecipes, setAllRecipes] = useState<Array<TRecipeAbridged>>([]);

    const userContext = useContext(UserContext);

    const fetchAndSetAllRecipes = useCallback((opts?: {
        successCallback?: () => void,
    }) => {
        const requestHeaders: HeadersInit = new Headers();
        const cookbookToken = getPublicCookbookToken();
        if (cookbookToken) {
            requestHeaders.set("X-Cookbook-Token", cookbookToken);
        }
        
        fetch('/api/recipes_metadata', { headers: requestHeaders })
            .then(response => response.json())
            .then(data => {
                const allNewRecipes = data as Array<TRecipeAbridged>;
                if (data) {
                    setAllRecipes(allNewRecipes);
                }

                if (opts?.successCallback) {
                    opts.successCallback();
                }
            });
    }, []);

    useEffect(() => {
        fetchAndSetAllRecipes();
    }, [userContext.loggedIn]);

    const maxIngredientsCount = useMemo(() =>
        Math.max(...allRecipes.map(r => r.ingredients.length)),
        [allRecipes]);
    const maxCookingTime = useMemo(() =>
        Math.max(...allRecipes.map(r => r.total_time ?? 0)),
        [allRecipes]);

    const updateRecipesFilter = (filter: string, opts?: { ingredientsLimit: number; timeLimit: number }) => {
        const numericalFilters: Array<TRecipeFilter> = [];
        if (opts) {
            numericalFilters.push({
                kind: 'INGREDIENTS_COUNT',
                query: opts.ingredientsLimit,
            });
            numericalFilters.push({
                kind: 'TIME',
                query: opts.timeLimit
            });
        }

        const processedFilter: Array<TRecipeFilter> = filter
            .split(' ')
            .map(f => f.trim())
            .filter(f => f !== '')
            .map(f => {
                if (f.startsWith('#')) {
                    return {
                        kind: 'TAG',
                        query: f.replace(/^#/, '')
                    }
                } else if (f.startsWith('ing:')) {
                    return {
                        kind: 'INGREDIENT',
                        query: f.replace(/^ing:/, '')
                    }
                } else {
                    return {
                        kind: 'TITLE',
                        query: f
                    }
                }
            });

        setRecipeFilter([...processedFilter, ...numericalFilters]);
    }

    // Filter recipes down to what's shown by the filter - using accumulator
    const filteredRecipes = recipeFilter.reduce((prevRecipes, currentFilter) => {
        switch (currentFilter.kind) {
            case 'TITLE':
                // filter down to titles that match query
                return prevRecipes.filter(recipe => {
                    return recipe.title.toLowerCase().includes(currentFilter.query.toLowerCase());
                });
            case 'INGREDIENTS_COUNT':
                return prevRecipes.filter(recipe => {
                    return recipe.ingredients.length <= currentFilter.query;
                });
            case 'TIME':
                return prevRecipes.filter(recipe => {
                    return (recipe.total_time ?? 0) <= currentFilter.query
                })
            case 'TAG':
                // filter down to tags that match query
                return prevRecipes.filter(recipe => {
                    return recipe.tags.map(t => t.name.toLowerCase()).includes(currentFilter.query.toLowerCase());
                });
            case 'INGREDIENT':
                // filter down to tags that match query
                // console.log(prevRecipes);
                return prevRecipes.filter(recipe => {
                    return !!recipe.ingredients.find(i => i.toLowerCase().includes(currentFilter.query.toLowerCase()));
                });
            default:
                // should not get to here, but will just no-op if here
                return prevRecipes;
        }
    }, allRecipes);

    const [sortOrder, setSortOrder] = useState<TRecipeSortOrder>("NEW_TO_OLD_DATE");
    const recipes = useMemo(() => {
        return sortedRecipes(filteredRecipes, sortOrder);
    }, [filteredRecipes, sortOrder])

    const [view, setView] = useState<"TILE" | "LIST">("TILE");

    const currentLocation = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const queryView = queryParams.get('view');
        const querySortOrder = queryParams.get('order');

        if (queryView) {
            setView(queryView.toUpperCase() as "TILE" | "LIST");
        }

        if (querySortOrder) {
            setSortOrder(querySortOrder.toUpperCase() as TRecipeSortOrder);
        }
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/search?view=${view}&order=${sortOrder}`, {replace: true, preventScrollReset: true})
    }, [navigate, view, sortOrder]);

    return <div className="search-page">
        <AddRecipeFab key="search-page"/>
        <TitleBar userContext={userContext}/>
        <SearchInput 
            maxCookingTime={maxCookingTime} 
            maxIngredientsCount={maxIngredientsCount} 
            autoFocus={true} 
            updateRecipesFilter={updateRecipesFilter} 
            location={"SEARCH"}
        />
        <div className="sort-and-view">
            <div className="sort-container">
                <select className="search-sort" name="sort-recipes" value={sortOrder} onChange={(e) => {
                    setSortOrder(e.target.value as TRecipeSortOrder)}
                }>
                    {recipeSortOrderOptions.map(o => <option key={o.value} value={o.value}>
                        {o.value === sortOrder ? o.display : o.label}
                    </option>)}
                </select>
            </div>
            <div className="view-container" onClick={() => setView(view === "TILE" ? "LIST" : "TILE")}>
                <img src={view === "TILE" ? listViewSvg : tileViewSvg } alt={view === "TILE" ? "show list view" : "show slide view"} />
            </div>
        </div>
        <div>
            {recipes.length === 0 && recipeFilter.length > 0 && <span>Unable to find any recipes that match your search. Let's add a new one!</span>}
            {view === "TILE" && recipes.map(recipe => 
                <RecipePanel
                    key={recipe.id}
                    recipe={recipe}
                    searchPage={true}
                    className="on-search-page"
                />
            )}
        </div>
        {view === "LIST" && <div className="recipes-list-container on-search-page">
            {recipes.map(recipe => 
                <RecipeRow 
                    key={recipe.id}
                    recipe={recipe}
                    hideAddBy={true}
                    showBookmark={true}
                />
            )}
        </div>}
    </div>
}