import React, { useCallback, useState } from "react";
import './imageDropzone.scss';
import { useDropzone } from 'react-dropzone';
import { getCsrfToken } from "./utils";

type TImageDropzoneProps = {
    className?: string;
    recipeId: number;
    onUploadAttemptComplete: (isNewCover: boolean) => void;
    autoOverrideCover: boolean;
}

type FileWithPreview = File & { preview?: string };

export const ImageDropzone = ({ 
    className, 
    recipeId, 
    onUploadAttemptComplete,
    autoOverrideCover
}: TImageDropzoneProps) => {
    const [images, setImages] = useState<FileWithPreview[]>([]);

    const onDrop = useCallback((acceptedFiles: FileWithPreview[]) => {
        console.log(acceptedFiles);
        setImages(
            acceptedFiles.map((image) =>
                Object.assign(image, {
                    preview: URL.createObjectURL(image),
                })
            )
        );
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        maxFiles: 5
    });

    const onClickUpload = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (images.length === 0) {
            return;
        }

        const formData = new FormData();
        formData.append('recipeId', recipeId.toString());

        images.forEach((image: File, idx: number) => {
            formData.append(`image_${idx}`, image);
            formData.append(`fileName_${idx}`, image.name);
        });

        // default to first image if it's a multi-image upload
        // todo @emikyu - make iscover something that is set via
        // a checkbox if image is being uploaded from the gallery
        const isCover = autoOverrideCover;
        formData.append('isCover', autoOverrideCover ? "true" : "false");

        const csrfToken = getCsrfToken();
        fetch('/api/recipe_images', {
            method: "POST",
            headers: {
                "X-CSRFToken": csrfToken ?? "",
            },
            body: formData
        }).then(() => {
            setImages([]);
            onUploadAttemptComplete(isCover)
        });
        
    }, [images, setImages]);

    const onClickCancel = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setImages([]);
    }, [setImages]);

    return <div className={className} >
        {images.length === 0 &&
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="image-dropzone-container">
                    {isDragActive ?
                        "Drop your recipe images here" :
                        "Drag and drop some recipe images here, or click to select images"
                    }
                </div>
            </div>}
        {images.length > 0 && <div>
            <div className="image-preview-title">Upload Preview</div>
            <div className="image-preview-container">
                {images.map(image =>
                    <img className="image-preview" key={image.name} src={image.preview} alt={image.name} />)
                }
            </div>
            <div className="image-upload-buttons">
                <button onClick={onClickUpload}>
                    Upload
                </button>
                <button onClick={onClickCancel}>
                    Cancel
                </button>
            </div>
        </div>
        }
    </div>;
}
