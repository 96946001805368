import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import xmarkSvg from '../../assets/xmark.svg';
import cloudUpSvg from '../../assets/cloudUp.svg';
import './recipePhotoUpload.scss';
import { useSwipeable } from "react-swipeable";
import { GoSpinner } from "./GoSpinner";

type TRecipePhotoUploadProps = {
    title: string;
    recipeId: number | string;
    onUploadAttemptComplete: (isNewCover: boolean) => void;
    autoOverrideCover: boolean;
    isVisible: false | "FROM_RECIPE" | "FROM_GALLERY";
    setIsVisible: (v: false | "FROM_RECIPE" | "FROM_GALLERY") => void;
    setShowPhotoGallery: (v: false | "FROM_RECIPE" | "FROM_UPLOAD") => void;
}

type FileWithPreview = File & { preview?: string };

const MAXIMUM_IMAGE_UPLOAD_LIMIT = 6;

export const RecipePhotoUpload = ({
    title,
    recipeId,
    onUploadAttemptComplete,
    autoOverrideCover,
    isVisible,
    setIsVisible,
    setShowPhotoGallery
}: TRecipePhotoUploadProps) => {
    const [images, setImages] = useState<FileWithPreview[]>([]);

    useEffect(() => {
        if (isVisible) {
            window.scrollTo(0, 0);
        }
    }, [isVisible]);

    const onDrop = useCallback((acceptedFiles: FileWithPreview[]) => {
        console.log(acceptedFiles);
        setImages(
            acceptedFiles.map((image) =>
                Object.assign(image, {
                    preview: URL.createObjectURL(image),
                })
            )
        );
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        maxFiles: MAXIMUM_IMAGE_UPLOAD_LIMIT,
    });

    const [isUploading, setIsUploading] = useState(false);

    const onClickUpload = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (isUploading) {
            // mitigate duplicate requests if there's one already in progress
            return;
        }

        setIsUploading(true);

        e.preventDefault();
        e.stopPropagation();
        if (images.length === 0) {
            return;
        }

        const formData = new FormData();
        formData.append('recipeId', recipeId.toString());

        images.forEach((image: File, idx: number) => {
            formData.append(`image_${idx}`, image);
            formData.append(`fileName_${idx}`, image.name);
        });

        // default to first image if it's a multi-image upload
        // todo @emikyu - make iscover something that is set via
        // a checkbox if image is being uploaded from the gallery
        const isCover = autoOverrideCover;
        formData.append('isCover', autoOverrideCover ? "true" : "false");

        const csrfToken = document.cookie.split(";").find(token => token.startsWith('csrftoken'))?.split('=')[1];
        fetch('/api/recipe_images', {
            method: "POST",
            headers: {
                "X-CSRFToken": csrfToken ?? "",
            },
            body: formData
        }).then(() => {
            setImages([]);
            onUploadAttemptComplete(isCover);
            setIsUploading(false);
        }).catch(() => {
            setIsUploading(false);
        });

    }, [images, setImages]);

    const onClickCancel = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setImages([]);
    }, [setImages]);

    const onClickExit = useCallback((e: React.MouseEvent<HTMLElement>) => {
        onClickCancel(e);
        if (isVisible === "FROM_GALLERY") {
            setShowPhotoGallery("FROM_UPLOAD");
        }
        setIsVisible(false);
    }, [onClickCancel, isVisible, setShowPhotoGallery, setIsVisible]);

    const swipeHandlers = useSwipeable({
        onSwipedRight: (e) => {
            setImages([]);
            if (isVisible === "FROM_GALLERY") {
                setShowPhotoGallery("FROM_UPLOAD");
            }
            setIsVisible(false);
        },
    });

    return <div 
        className={`recipe-photo-dropzone-container${isVisible ? " show" : ""}${isVisible === "FROM_RECIPE" ? " from-recipe" : ""}`} 
        {...swipeHandlers}>
        <div className="recipe-photo-dropzone-close" onClick={onClickExit}>
            <img src={xmarkSvg} alt="close" />
        </div>
        <div className={`recipe-photo-dropzone-title`}>
            {title} Photo Upload
        </div>
        {images.length === 0 &&
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="image-dropzone-container">
                    <div className="upload-icon">
                        <img src={cloudUpSvg} alt="Upload" />
                    </div>
                    <div className="user-prompt">
                        {isDragActive ?
                            "Drop your recipe images here" :
                            "Drag and drop some recipe images here, or click to select images"
                        }
                    </div>
                </div>
            </div>}
        {images.length > 0 && <div>
            <div className="image-preview-container">
                {images.map(image =>
                    <img className="image-preview" key={image.name} src={image.preview} alt={image.name} />)
                }
            </div>
            <div className="image-upload-buttons">
                <button className="highlight" onClick={onClickUpload}>
                    {isUploading ? <GoSpinner color={"#46786e"} className="photo-upload-spinner"/> :"Upload"}
                </button>
                <button onClick={onClickCancel}>
                    Cancel
                </button>
            </div>
        </div>
        }
    </div>;
}
