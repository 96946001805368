import { TViewer } from "./user";

export const cookbookPermOptions = [
    { value: "READ", label: "Viewer"},
    { value: "WRITE", label: "Editor"},
    { value: "ADMIN", label: "Admin" },
] as const;

export type TCookbookPerm = typeof cookbookPermOptions[number]["value"];

type TUserAbridged = {
    first_name?: string;
    username: string;
    setting: {
        image_src?: string;
    }
}

type TTag = {
    id: number;
    name: string;
};

export type TRecipeAbridged = {
    id: number,
    title: string;
    image?: string;
    total_time?: number;
    ingredients: Array<string>;
    created_at: string;
    uploader: TUserAbridged;
    tags: TTag[];
}

export type TCookbook = {
    id: number;
    title: string;
    image?: string;
    creator: TUserAbridged;
    recipes: Array<TRecipeAbridged>;
    viewers: TViewer[]
};
