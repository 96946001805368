import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TRecipe, TRecipeSortOrder, sortedRecipes } from "../../types/recipe";
import './recipeCover.scss';
import placeholder from '../../assets/cookingPot.svg';
import addImageSvg from '../../assets/addImage.svg';
import viewGallerySvg from '../../assets/viewGallery.svg';
import leftArrowSvg from '../../assets/leftArrow.svg';
import bookmarkSvg from '../../assets/bookmarkGreen.svg';
import bookmarkSolidSvg from '../../assets/bookmarkSolidGreen.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { PlannerContext } from "../../appContext";

type TGalleryImage = { src: string };
type TRecipeCoverProps = {
    recipe: TRecipe;
    gallery: TGalleryImage[];
    setShowPhotoGallery: (v: false | "FROM_RECIPE" | "FROM_UPLOAD") => void;
    setShowPhotoUpload: (v: false | "FROM_RECIPE" | "FROM_GALLERY") => void;
    inPlanner: boolean;
}

export const RecipeCover = ({ recipe, gallery, setShowPhotoGallery, setShowPhotoUpload, inPlanner } : TRecipeCoverProps) => {
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const sortOrder: TRecipeSortOrder |  null = useMemo(() => {
        const queryParams = new URLSearchParams(currentLocation.search);
        const sortOrder = queryParams.get('cookbook');

        if (!sortOrder) {
            return null;
        }
        return sortOrder as TRecipeSortOrder;
    }, [currentLocation]);

    const { addToPlanner, currentPlanner, removeFromPlanner } = useContext(PlannerContext);
    const isBookmarked = useMemo(() => {
        return recipe.id in currentPlanner.groceries;
    }, [recipe.id, currentPlanner.groceries]);

    const toggleBookmark = useCallback(() => {
        if (!isBookmarked) {
            addToPlanner(recipe.id);
            return;
        }
        removeFromPlanner(recipe.id)
    }, [addToPlanner, removeFromPlanner, recipe.id, isBookmarked]);

    const cursor: {before: number; after: number; sortOrder: TRecipeSortOrder} | undefined = useMemo(() => {
        if (!sortOrder) {
            return;
        }

        const recipes = sortedRecipes(recipe.cookbook.recipes, sortOrder);
        const currIdx = recipes.findIndex(r => r.id === recipe.id);

        const beforeIdx = currIdx > 0 ? currIdx - 1 : recipes.length - 1;
        const afterIdx = currIdx < recipes.length - 1 ? currIdx + 1 : 0;

        return {
            before: recipes[beforeIdx].id,
            after: recipes[afterIdx].id,
            sortOrder
        }
    }, [sortOrder, recipe]);

    const handleSwipe = useCallback((dir: "LEFT" | "RIGHT") => {
        if (!cursor) {
            return;
        }

        navigate(`/recipe/${dir === "LEFT" ? cursor.after : cursor.before}?cookbook=${cursor.sortOrder}`, {replace: true});
    }, [cursor]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (_) => {
            handleSwipe("LEFT");
        },
        onSwipedRight: (_) => {
            handleSwipe("RIGHT");
        }
    });

    return <>
        <div className="recipe-top-nav">
            <div className="left">
                <div 
                    className="navigate-back action-icon"
                    onClick={() => {
                        navigate(-1); 
                    }}>
                    <img src={leftArrowSvg} alt="navigate back" />
                </div>
                {!inPlanner && <div 
                    className="toggle-bookmark action-icon" 
                    onClick={toggleBookmark}>
                    <img src={isBookmarked ? bookmarkSolidSvg : bookmarkSvg } alt="bookmark toggle" />
                </div>}
            </div>
            <div className="right">
                {gallery.length > 1 && <div className="view-gallery action-icon" onClick={() => setShowPhotoGallery("FROM_RECIPE")}>
                    <img src={viewGallerySvg} alt="open photo gallery" />
                </div>}
                <div className="add-image action-icon" onClick={() => setShowPhotoUpload("FROM_RECIPE")}>
                    <img src={addImageSvg} alt="upload new photo" />
                </div>
            </div>
        </div>
        <div className="recipe-page-cover-container" {...swipeHandlers}>
            <div className="recipe-cover-image-shadow" />
            <img src={recipe.image ? `/recipe_images/${recipe.image}` : placeholder } alt={`Photo of ${recipe.title}`} />
        </div>
    </>
}